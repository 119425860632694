import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { AddressLookupV2Component, IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';
import {
  AddressListDropdownQuestionComponent,
  ButtonGroupQuestionComponent,
  CheckboxButtonGroupQuestionComponent,
  DateQuestionComponent,
  DropdownQuestionComponent,
  FileUploadQuestionComponent,
  GeneralQuestionComponent,
  GoogleMapsMarkerQuestionComponent,
  GoogleMapsMultiMarkerQuestionComponent,
  GooglePlacesAutocompleteComponent,
  I18nKeys,
  ImageOrFileUploadQuestionComponent,
  ImageUploadQuestionComponent,
  MultiDropdownWithValueQuestionComponent,
  MultiImageUploadQuestionComponent,
  OcrImageUploadQuestionComponent,
  PostcodeQuestionComponent,
  PostcodeQuestionV2Component,
  SignatureQuestionComponent,
  TextareaQuestionComponent,
  TextboxQuestionComponent,
  ToggleQuestionComponent
} from '@jump-tech-frontend/cards';
import {
  FormUpdate,
  QuestionHintComponent,
  VideoCaptureComponent,
  PhoneNumberComponent
} from '@jump-tech-frontend/question-components';
import * as Analytics from '../../app.analytics';
import { shouldTrackQuestion } from '../../app.analytics';
import { NgSelectDropdownComponent } from '../../shared/form-components/ng-select-dropdown.component';
import { CardsLibTranslationService } from '../cards-lib-translation.service';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { ProjectSchedulerComponent } from '../project-scheduler/project-scheduler.component';
import { ArrayQuestionComponent } from './array-question.component';
import { AttachmentQuestionComponent } from './attachment-question.component';
import { AvailabilityQuestionComponent } from './availability-question.component';
import { AppDateTimeDurationSelectComponent } from './date-time-selector.component';
import { MultiTextWithAttachment } from './multi-text-with-attachment.component';
import { NowDateQuestionComponent } from './now-date-question.component';

export const updateProjectAttachments = ($event: any[], project: any, key: string) => {
  if (!project) {
    return;
  }
  project.attachments = project.attachments || [];
  if ($event && $event.length) {
    project.attachments = project.attachments.concat($event);
  } else {
    const idx = project.attachments.findIndex(a => a.key.startsWith(`${key}.`));
    if (idx > -1) {
      project.attachments.splice(idx, 1);
    }
  }
};

@Component({
  selector: 'app-edit-layout-questions',
  template: `
    <div *ngIf="form" [ngSwitch]="question.controlType" [formGroup]="form" class="form-group edit-layout">
      <crds-question-postcode
        *ngSwitchCase="'PostcodeQuestion'"
        [question]="question"
        [i18ns]="i18ns"
        [form]="form"
      ></crds-question-postcode>
      <crds-question-v2-postcode
        *ngSwitchCase="'PostcodeV2Question'"
        [question]="question"
        [i18ns]="i18ns"
        [form]="form"
      >
      </crds-question-v2-postcode>
      <crds-google-places-autocomplete
        *ngSwitchCase="'GooglePlacesAddress'"
        [question]="question"
        [i18ns]="i18ns"
        [form]="form"
      >
      </crds-google-places-autocomplete>
      <jump-tech-frontend-address-lookup-v2
        *ngSwitchCase="'AddressTypeaheadQuestion'"
        [countryCodes]="question.countryCodes"
        [label]="question.label"
        [provider]="question.provider"
        [address]="form.get(question.key).value"
        (addressUpdate)="updateAddress($event, question.key)"
        [required]="question.required"
        [errorMessage]="question.errorMessage"
      >
      </jump-tech-frontend-address-lookup-v2>
      <crds-question-dropdown
        *ngSwitchCase="'DropdownQuestion'"
        [project]="project"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-dropdown>
      <app-ng-select-dropdown
        *ngSwitchCase="'NgSingleselectDropdown'"
        [project]="project"
        [singleSelection]="true"
        [question]="question"
        [form]="form"
        (change)="handleChange($event)"
      ></app-ng-select-dropdown>
      <app-ng-select-dropdown
        *ngSwitchCase="'NgMultiselectDropdown'"
        [project]="project"
        [singleSelection]="false"
        [question]="question"
        [form]="form"
        (change)="handleChange($event)"
      ></app-ng-select-dropdown>
      <ng-container *ngSwitchCase="'DateTimeDurationSelect'">
        <app-date-time-duration-select
          *ngIf="!this.newSchedulerEnabled"
          [question]="question"
          [form]="form"
          [project]="project"
        ></app-date-time-duration-select>
        <project-scheduler
          [project]="project"
          [question]="question"
          [v3Enabled]="schedulerV3Enabled"
          *ngIf="this.newSchedulerEnabled"
        ></project-scheduler>
      </ng-container>
      <crds-question-multi-dropdown-value
        *ngSwitchCase="'MultiDropdownQuestion'"
        [project]="project"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-multi-dropdown-value>
      <crds-address-list-question-dropdown
        *ngSwitchCase="'AddressDropdownQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-address-list-question-dropdown>
      <ng-container *ngSwitchCase="'MapsMarkerQuestion'">
        <crds-question-google-maps-marker [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-google-maps-marker>
      </ng-container>
      <crds-question-general
        *ngSwitchCase="'GeneralText'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-general>
      <crds-question-image-upload
        *ngSwitchCase="'ImageUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-image-upload>
      <crds-question-multi-image-upload
        *ngSwitchCase="'MultiImageUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-question-multi-image-upload>
      <crds-question-ocr-image-upload
        *ngSwitchCase="'OcrImageUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-question-ocr-image-upload>
      <crds-question-signature
        *ngSwitchCase="'SignatureQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-signature>
      <crds-question-textbox
        *ngSwitchCase="'TextboxQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-textbox>
      <crds-question-date
        *ngSwitchCase="'DateQuestionComponent'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-date>
      <crds-question-textarea
        *ngSwitchCase="'TextareaQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-textarea>
      <crds-question-toggle
        *ngSwitchCase="'ToggleQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-toggle>
      <crds-question-button-group
        *ngSwitchCase="'ButtonGroupQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      >
      </crds-question-button-group>
      <crds-question-checkbox-button-group
        *ngSwitchCase="'CheckboxButtonGroupQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-checkbox-button-group>
      <app-attachment-question
        *ngSwitchCase="'AttachmentQuestion'"
        [question]="question"
        [project]="project"
        [form]="form"
        [notify]="isNewProject"
        (valueUpdated)="updateAttachments($event)"
      ></app-attachment-question>
      <app-now-date-question
        *ngSwitchCase="'NowDateQuestion'"
        [question]="question"
        [form]="form"
        setDefault="true"
      ></app-now-date-question>
      <app-multi-text-with-attachment
        *ngSwitchCase="'MultiTextAttachment'"
        [question]="question"
        [project]="project"
        [form]="form"
        limit="5"
      ></app-multi-text-with-attachment>
      <app-array-question *ngSwitchCase="'ArrayQuestion'" [question]="question" [project]="project" [form]="form">
      </app-array-question>
      <crds-question-file-upload
        *ngSwitchCase="'FileUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-file-upload>
      <crds-question-image-or-file-upload
        *ngSwitchCase="'ImageOrFileUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-question-image-or-file-upload>
      <crds-question-google-maps-multi-marker
        *ngSwitchCase="'MultiMarkerQuestion'"
        [question]="question"
        [form]="form"
      ></crds-question-google-maps-multi-marker>
      <crds-availability
        *ngSwitchCase="'AvailabilityQuestion'"
        [question]="question"
        [form]="form"
        [project]="project"
        (change)="handleChange($event)"
      ></crds-availability>
      <crds-question-hint *ngSwitchCase="'QuestionHint'" [question]="question"></crds-question-hint>
      <question-video-capture
        *ngSwitchCase="'VideoUploadQuestion'"
        [params]="{
          key: question.key,
          label: question.label,
          required: question.required,
          value: this.form.get(question.key).value
        }"
        (formChange)="handleFormChange($event)"
      ></question-video-capture>
      <question-phone-number
        *ngSwitchCase="'PhoneQuestion'"
        [params]="{
          key: question.key,
          label: question.label,
          required: question.required,
          value: this.form.get(question.key).value
        }"
        (formChange)="handleFormChange($event)"
      />
    </div>
  `,
  styleUrls: ['edit-layout-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    ReactiveFormsModule,
    NgSwitchCase,
    AddressLookupV2Component,
    NgSelectDropdownComponent,
    AppDateTimeDurationSelectComponent,
    ProjectSchedulerComponent,
    AttachmentQuestionComponent,
    NowDateQuestionComponent,
    MultiTextWithAttachment,
    ArrayQuestionComponent,
    AvailabilityQuestionComponent,
    VideoCaptureComponent,
    FileUploadQuestionComponent,
    ImageOrFileUploadQuestionComponent,
    GoogleMapsMultiMarkerQuestionComponent,
    QuestionHintComponent,
    TextareaQuestionComponent,
    ToggleQuestionComponent,
    ButtonGroupQuestionComponent,
    DateQuestionComponent,
    TextboxQuestionComponent,
    SignatureQuestionComponent,
    OcrImageUploadQuestionComponent,
    MultiImageUploadQuestionComponent,
    ImageUploadQuestionComponent,
    GeneralQuestionComponent,
    GoogleMapsMarkerQuestionComponent,
    AddressListDropdownQuestionComponent,
    MultiDropdownWithValueQuestionComponent,
    GooglePlacesAutocompleteComponent,
    PostcodeQuestionV2Component,
    PostcodeQuestionComponent,
    DropdownQuestionComponent,
    CheckboxButtonGroupQuestionComponent,
    PhoneNumberComponent
  ]
})
export class EditLayoutQuestionsComponent implements OnInit {
  @Input() question: any;
  @Input() form: UntypedFormGroup;
  @Input() project?: any;
  i18ns: I18nKeys;
  schedulerV3Enabled: boolean;
  newSchedulerEnabled: boolean;

  constructor(
    private cardsLibI18nService: CardsLibTranslationService,
    private featureFlagService: FeatureFlagService
  ) {}

  get isNewProject(): boolean {
    return this.project && !this.project['tenant'];
  }

  async ngOnInit() {
    this.i18ns = this.cardsLibI18nService.loadTranslations();
    const schedulerV2Phase2Enabled = await this.featureFlagService.isFeatureEnabled('scheduler-v2-phase-2');
    this.schedulerV3Enabled = await this.featureFlagService.isFeatureEnabled('scheduler-v3');
    this.newSchedulerEnabled = schedulerV2Phase2Enabled || this.schedulerV3Enabled;
  }

  handleChange(evt) {
    if (shouldTrackQuestion(evt.target.id)) {
      Analytics.logEvent(`Question: ${evt.target.id}`, { answer: evt.target.value });
    }
  }

  updateAttachments($event: any[]) {
    updateProjectAttachments($event, this.project, this.question.key);
  }

  updateAddress($event: { address: IAddressV2; location: string }, key: string) {
    this.form.get(key)?.setValue($event.address);
    this.form.get('postCode')?.setValue($event.address?.postCode);
    this.form.get('installationLatLng')?.setValue($event.location);
  }

  handleFormChange($event: FormUpdate) {
    this.form.get($event.key)?.setValue($event.value);
  }
}
