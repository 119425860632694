import { IAddressV2 } from '../domain/address-v2';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';

export function getAddressFieldsForCountry(countryCode: string): (keyof IAddressV2)[] {
  switch (countryCode.toLowerCase()) {
    case 'gb':
    case 'ie':
      return ['line1', 'line2', 'line3', 'town', 'county', 'postCode'];
    case 'ae':
      return ['line1', 'line2', 'line3', 'postCode', 'county', 'country'];
    default:
      return ['line1', 'line2', 'line3', 'postCode', 'town', 'county'];
  }
}

export function getAddressLinesForCountry(countryCodes: string[], address: IAddressV2 | null): string[] {
  let addressLines: string[];
  // todo Get QA to test this to make sure we havent broken it
  const countryCode =
    countryCodes && countryCodes.length ? (address?.countryCode ?? countryCodes[0]).toLowerCase() : 'gb';
  switch (countryCode) {
    case 'gb':
    case 'ie':
      addressLines = [
        address?.line1 ?? '',
        address?.line2 ?? '',
        address?.line3 ?? '',
        address?.town ?? '',
        address?.county ?? '',
        address?.postCode ?? ''
      ];
      break;
    case 'nl':
      addressLines = [
        address?.line1 ?? '',
        address?.line2 ?? '',
        address?.line3 ?? '',
        [address?.postCode ?? '', address?.town?.toUpperCase() ?? ''].join('&nbsp;&nbsp;'),
        address?.county ?? ''
      ];
      break;
    case 'ae':
      addressLines = [
        address?.line1 ?? '',
        address?.line2 ?? '',
        address?.line3 ?? '',
        address?.postCode ?? '',
        address?.county ?? '',
        address?.country ?? ''
      ];
      break;
    default:
      addressLines = [
        address?.line1 ?? '',
        address?.line2 ?? '',
        address?.line3 ?? '',
        [address?.postCode ?? '', address?.town?.toUpperCase() ?? ''].join(' '),
        address?.county ?? ''
      ];
  }
  return addressLines;
}

export function getPostcodeValidatorForCountry(countryCodes: string[], addressCountryCode?: string): ValidatorFn[] {
  const countriesWithOptionalPostCode = ['ae'];
  const validators = [Validators.required];

  if (addressCountryCode) {
    countryCodes = [addressCountryCode];
  }
  for (const countryCode of countryCodes) {
    if (countriesWithOptionalPostCode.includes(countryCode.toLowerCase())) {
      return [];
    }
  }
  return validators;
}

export function isFormFieldRequired(form: FormGroup, field: string): boolean {
  return form.get(field)?.hasValidator(Validators.required) ?? false;
}

export function transformLegacyAddress(address?: (IAddressV2 & { locality?: string }) | null) {
  if (!address || !address.line1 || !address.town || !address.postCode) {
    return null;
  }
  if (!address?.locality) {
    return address;
  }
  const locality = address.locality;
  delete address.locality;
  return {
    ...address,
    line3: [address?.line3 ?? '', locality ?? ''].filter(x => !!x).join(', ')
  };
}
