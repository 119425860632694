<div class="ena-application__display-text-groups">
  @for (displayTextGrp of vm.displayTextGroups; track $index) {
    <jui-card class="ena-application__display-text-group" size="sm">
      <div slot="header" class="ena-application__display-text-group-header" (click)="toggleDisplayTextGroup(displayTextGrp.title)">
          <span class="ena-application__display-text-group-header-title">
            {{displayTextGrp.title}}
          </span>
          <div class="ena-application__display-text-group-header-actions">
            @if (displayTextGrp.headerString) {
              <span class="ena-application__display-text-group-header-text">{{ displayTextGrp.headerString }}</span>
            }
            <span>
              <jui-icon name="expand_more" [name]="displayTextGrp.toggleClass" [color]="displayTextGrp.toggleColor" size="sm"></jui-icon>
            </span>
          </div>
      </div>
      <div
        #collapse="ngbCollapse"
        [ngbCollapse]="!displayTextGrp.isOpen"
        (ngbCollapseChange)="displayTextGrp.isOpen = $event"
      >
        @if (displayTextGrp.displayEmptyText) {
          <span class="ena-application__display-text-group-empty">{{ displayTextGrp.emptyEntriesString }}</span>
        } @else {
          <span class="ena-application__display-text-group-timestamp"> {{displayTextGrp.formattedTimestamp}} </span>
          <div class="ena-application__display-text-group-entries">
            @for (entry of displayTextGrp.entries; track $index) {
              <div class="ena-application__display-text-group-key"> {{entry.key}} </div>
              <div> {{entry.value}} </div>
            }
          </div>
        }
      </div>
    </jui-card>
  }
</div>
