<div class="schedule-event-selected-job-details__slot-row" [formGroup]="vm.form" [attr.data-qa]="'specificSlotRow'">
  <ng-container [formGroupName]="tradespersonId">
    <ng-container formGroupName="slots">
      <ng-container [formArrayName]="index">
        <!-- todo refactor these components as the slot and job ones are basically the same -->
        <div class="schedule-event-selected-job-details__input">
          <div class="input-group">
            <input
              class="form-control schedule-event-selected-job-details__calendar-input"
              [placeholder]="vm.i18nStartDatePlaceholder"
              [attr.data-qa]="vm.qaStartDateInput"
              name="dp"
              id="startDate"
              formControlName="startDate"
              ngbDatepicker
              #startDate="ngbDatepicker"
              (input)="slotDateChange({ tradespersonId, index, control: 'startDate', type: 'start' })"
              (dateSelect)="slotDateChange({ tradespersonId, index, control: 'startDate', type: 'start' })"
              [minDate]="getStartMinDate()"
              [maxDate]="getStartMaxDate(tradespersonId, index)"
            />
            <div class="input-group-append">
              <button
                class="schedule-event-selected-job-details__calendar-button"
                (click)="startDate.toggle()"
                [disabled]="vm.actionsDisabled"
                type="button"
                [attr.data-qa]="vm.qaStartDateBtn"
              >
                <span class="schedule-event-selected-job-details__calendar-icon">
                  <jui-icon name="event" size="sm" color="secondary"></jui-icon>
                </span>
              </button>
            </div>
          </div>
          <div
            *ngIf="
              getSlotFormControl(tradespersonId, index, 'startDate').errors &&
              getSlotFormControl(tradespersonId, index, 'startDate').dirty
            "
            class="schedule-event-selected-job-details__form-error"
            [attr.data-qa]="'scheduleEventFormError'"
          >
            <div *ngIf="getSlotFormControl(tradespersonId, index, 'startDate').errors?.required">
              {{ vm.i18nStartDateRequired }}
            </div>
            <div *ngIf="getSlotFormControl(tradespersonId, index, 'startDate').errors?.ngbDate?.invalid">
              {{ vm.i18nInvalidDateFormat }}
            </div>
            <div *ngIf="getSlotFormControl(tradespersonId, index, 'startDate').errors?.ngbDate?.maxDate">
              {{ vm.i18nStartDateAfterEnd }}
            </div>
          </div>
        </div>
        <div
          class="schedule-event-selected-job-details__input schedule-event-selected-job-details__input--time-control"
        >
          <ng-select
            class="schedule-event-selected-job-details__time-input"
            formControlName="startTime"
            id="startTime"
            bindLabel="label"
            bindValue="name"
            [items]="vm.timeslots"
            [clearable]="false"
            [attr.data-qa]="vm.qaStartTimeSelect"
            (change)="slotDateChange({ tradespersonId, index, control: 'startTime', type: 'start'})"
          ></ng-select>
        </div>
        <div class="schedule-event-selected-job-details__input">
          <div class="input-group">
            <input
              class="form-control schedule-event-selected-job-details__calendar-input"
              [placeholder]="vm.i18nEndDatePlaceholder"
              name="dp"
              id="endDate"
              formControlName="endDate"
              ngbDatepicker
              #endDate="ngbDatepicker"
              [attr.data-qa]="vm.qaEndDateInput"
              (input)="slotDateChange({ tradespersonId, index, control: 'endDate', type: 'end' })"
              (dateSelect)="slotDateChange({ tradespersonId, index, control: 'endDate', type: 'end' })"
              [minDate]="getEndMinDate(tradespersonId, index)"
              [maxDate]="getEndMaxDate()"
            />
            <div class="input-group-append">
              <button
                class="schedule-event-selected-job-details__calendar-button"
                (click)="endDate.toggle()"
                [disabled]="vm.actionsDisabled"
                type="button"
                [attr.data-qa]="vm.qaEndDateBtn"
              >
                <span class="schedule-event-selected-job-details__calendar-icon">
                  <jui-icon name="event" size="sm" color="secondary"></jui-icon>
                </span>
              </button>
            </div>
          </div>
          <div
            *ngIf="
              getSlotFormControl(tradespersonId, index, 'endDate').errors &&
              getSlotFormControl(tradespersonId, index, 'endDate').dirty
            "
            class="schedule-event-selected-job-details__form-error"
            [attr.data-qa]="'scheduleEventFormError'"
          >
            <div *ngIf="getSlotFormControl(tradespersonId, index, 'endDate').errors?.required">
              {{ vm.i18nEndDateRequired }}
            </div>
            <div *ngIf="getSlotFormControl(tradespersonId, index, 'endDate').errors?.ngbDate?.invalid">
              {{ vm.i18nInvalidDateFormat }}
            </div>
            <div *ngIf="getSlotFormControl(tradespersonId, index, 'endDate').errors?.ngbDate?.minDate">
              {{ vm.i18nEndDateBeforeStart }}
            </div>
          </div>
        </div>
        <div
          class="schedule-event-selected-job-details__input schedule-event-selected-job-details__input--time-control"
        >
          <ng-select
            class="schedule-event-selected-job-details__time-input"
            formControlName="endTime"
            id="endTime"
            bindLabel="label"
            bindValue="name"
            [items]="vm.timeslots"
            [clearable]="false"
            [attr.data-qa]="vm.qaEndTimeSelect"
            (change)="slotDateChange({ tradespersonId, index, control: 'endTime', type: 'end' })"
          ></ng-select>
        </div>
        @if (!vm.readonly) {
          <div class="schedule-event-selected-job-details__slot-icon">
            <jui-icon
              name="delete"
              color="high"
              size="xs"
              type="outlined"
              [title]="vm.i18nRemoveTradespersonBtn"
              [attr.data-qa]="'removeTimeslotBtn'"
              [disabled]="vm.actionsDisabled"
              (click)="removeTradespersonSlot.emit({tradespersonId, index})"
            ></jui-icon>
          </div>
        }
      </ng-container>
    </ng-container>
  </ng-container>
</div>
@if (vm.hasConstraintError) {
  <div class="schedule-event-selected-job-details__error-row">
    <div class="schedule-event-selected-job-details__form-error" [attr.data-qa]="'scheduleEventFormError'">
      @if (vm.isTimeInvalid) {
       {{ vm.i18nTimeIsInvalid }}
      }
      @if (vm.isTimeOutsideJob) {
        {{ vm.i18nTimeNotWithinJob }}
      }
    </div>
  </div>
}



