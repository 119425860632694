import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Project } from '../../core/domain/project';
import { ProjectStatusChangeRepository } from './project-status-change.repository';
import { ProjectStatusChangeDm, ProjectStatusChangeVm } from './project-status-change.model';
import { ProjectState } from '../../core/domain/project-configuration';

@Injectable({ providedIn: 'root' })
export class ProjectStatusChangePresenter {
  viewModel: ProjectStatusChangeVm;

  constructor(private repository: ProjectStatusChangeRepository) {}

  load(vmSubject$: Subject<ProjectStatusChangeVm>, project: Project, projectStates: ProjectState[]): void {
    this.repository.load(project, projectStates, (dm: ProjectStatusChangeDm) => {
      this.viewModel = {
        projectStatuses: dm.projectStatuses,
        selectedStatus: dm.selectedStatus,

        visible: dm.visible,
        disabled: dm.disabled,

        i18nProjectStatusChangeLabel: dm.i18n.label,
        i18nProjectStatusChangePlaceholder: dm.i18n.placeholder
      };

      vmSubject$.next(this.viewModel);
    });
  }

  async changeProjectStatus(status: ProjectState | undefined): Promise<void> {
    await this.repository.changeProjectStatus(status);
  }
}
