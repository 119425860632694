import { NgIf } from '@angular/common';
import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechDateSettings } from '@jump-tech-frontend/domain';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { CHANGE_PASSWORD_PATH, RESET_PASSWORD_PATH, SETUP_ATOM_PATH } from '../../app.routes';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { PathwayConfigurationService } from '../../auth/services/pathway-configuration.service';
import { UserService } from '../../auth/services/user.service';
import { AddProjectButtonComponent } from '../../dashboards/buttons/add-project-button.component';
import {
  DropDownElement,
  MultipleSelectionDropdownComponent
} from '../../shared/form-components/multiple-selection-dropdown.component';
import { AlertModalComponent } from '../../shared/modals/alert-modal.component';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { FirstCharsPipe } from '../../shared/pipes/first-chars.pipe';
import { DefaultRouteService } from '../default-route.service';
import { User } from '../domain/user';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { FeatureFlagDirective } from '../feature-flag/featureFlagDirective';
import { LocalStorageGateway } from '../local-storage-gateway.service';
import { ThemeOption, UserThemeService } from '../user-theme.service';
import { atomSetupPageUrl } from '../utils/atom';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  providers: [FirstCharsPipe, DefaultRouteService],
  standalone: true,
  imports: [
    NgIf,
    AddProjectButtonComponent,
    FeatureFlagDirective,
    NgbDropdownModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
    CoreComponentsAngularModule,
    TranslocoModule,
    MultipleSelectionDropdownComponent
  ]
})
export class UserPanelComponent implements OnInit {
  get isUserSubTenant(): boolean {
    return this._isUserSubTenant;
  }

  set isUserSubTenant(value: boolean) {
    this._isUserSubTenant = value;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    return this.isMobileScreen;
  }

  get isMobileScreen(): boolean {
    return window.innerWidth <= 500;
  }

  user: User;
  timezoneList: DropDownElement[] = [];
  themeOptions: ThemeOption[];
  routeOptions: DropDownElement[];
  currentDefaultRoute: DropDownElement;
  userThemePreference: ThemeOption = null;

  private destroyRef: DestroyRef = inject(DestroyRef);
  private _isUserSubTenant = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private pathwayConfiguration: PathwayConfigurationService,
    private modalService: NgbModal,
    private featureAccessService: AccessService,
    private translocoService: TranslocoService,
    private themeService: UserThemeService,
    private defaultRouteService: DefaultRouteService,
    private featureFlagService: FeatureFlagService,
    private localStorageService: LocalStorageGateway
  ) {}

  async ngOnInit(): Promise<void> {
    this.initUserTheme();
    this.timezoneList = this.initTimezoneList();
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (user: User) => {
      if (user !== null) {
        this.user = user;
        this.checkForSubTenant();

        await this.defaultRouteService.init();
        this.routeOptions = this.defaultRouteService.defaultRoutes;
        this.currentDefaultRoute = this.defaultRouteService.currentDefaultRoute;
      }
    });
  }

  get isSignedIn() {
    return this.authenticationService.isUserSignedIn;
  }

  signOut() {
    this.authenticationService.signOut().then();
  }

  changePassword() {
    this.router.navigate([CHANGE_PASSWORD_PATH]).then();
  }

  resetPassword() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'sm' });
    modalRef.componentInstance.config = {
      title: this.translocoService.translate('common.modals.resetPassword.title'),
      messages: [
        this.translocoService.translate('common.modals.resetPassword.messages.message'),
        this.translocoService.translate('common.modals.resetPassword.messages.description')
      ]
    };
    const self = this;
    modalRef.result
      .then(async () => {
        await self.spinnerService.show();
        self.userService
          .resetPassword()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
            async () => {
              await self.spinnerService.hide();
              // Now we have to navigate to the reset password page so they can reset everything
              await self.router.navigate([RESET_PASSWORD_PATH], {
                replaceUrl: true,
                queryParams: { id: self.user.userName }
              });
            },
            async error => {
              self.showError(error);
              await self.spinnerService.hide();
            }
          );
      })
      .catch(() => console.log('Reset Password Modal dismissed'));
  }

  isInstaller() {
    return this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.ShowSetupAtom);
  }

  async openAtomConfig() {
    const newAtomSetupEnabled = await this.featureFlagService.isFeatureEnabled('new-atom-login');

    console.log({ newAtomSetupEnabled });

    if (newAtomSetupEnabled) {
      await this.router.navigate([SETUP_ATOM_PATH]);
    } else {
      const locale = this.user?.accessInfo?.configuration?.locale;
      const language = locale?.split('-')?.[0];
      const url = atomSetupPageUrl(this.pathwayConfiguration.tenant, environment.name, language);
      window.open(url, url);
    }
  }

  showError(error: any) {
    let errorMessage;
    if (error.hasOwnProperty('error')) {
      const errorObject = error.error;
      if (errorObject.hasOwnProperty('errorMessage')) {
        errorMessage = errorObject.errorMessage;
      } else {
        errorMessage = errorObject.toString();
      }
    } else {
      errorMessage = error.toString();
    }
    if (!environment.production) {
      console.log(error);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.title = this.translocoService.translate('common.error');
    modalRef.componentInstance.messages = [errorMessage];
  }

  private checkForSubTenant() {
    this.isUserSubTenant =
      this.user.accessInfo && this.user.accessInfo.subTenants && this.user.accessInfo.subTenants.length > 0;
  }

  initUserTheme() {
    this.themeOptions = this.themeService.getAvailableThemes();
    this.userThemePreference = this.themeService.getActiveTheme();
  }

  updateDefaultRoute(selectedRoute: { label: string; id: string }) {
    this.defaultRouteService.updateDefaultRoute(selectedRoute.id);
  }

  switchTheme() {
    this.themeService.setTheme(this.userThemePreference);
  }

  initTimezoneList() {
    const timezones = Intl.supportedValuesOf('timeZone');
    return timezones.map(timezone => {
      return { id: timezone, name: timezone.replaceAll('_', ' '), label: timezone };
    });
  }

  getCurrentTimeZone() {
    return [JumptechDateSettings.defaultTimeZone];
  }

  setSelectedTimeZone(timezone: string) {
    JumptechDateSettings.defaultTimeZone = timezone;
    window.location.reload();
  }
}
