import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnaApplicationPresenter } from './ena-application.presenter';
import { BehaviorSubject } from 'rxjs';
import { EnaFormVm } from './ena.model';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbCollapseModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EnaApplicationErrorsComponent } from './ena-application-errors/ena-application-errors.component';
import { EnaApplicationErrorsPresenter } from './ena-application-errors/ena-application-errors.presenter';
import { AddressLookupV2Component } from '@jump-tech-frontend/address-lookup-v2';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GalleryV2LayoutComponent } from '../../core/cardLayouts/gallery-v2/gallery-v2-layout.component';
import { DisplayTabLayout } from '../project-detail.component';
import { Project } from '../../core/domain/project';
import { EnaApplicationActionsComponent } from './ena-application-actions/ena-application-actions.component';
import { CustomAttachment } from '../../core/domain/project-configuration';
import { EnaApplicationAttachmentsPresenter } from './ena-application-attachments/ena-application-attachments.presenter';
import { EnaApplicationGroupsComponent } from './ena-application-groups/ena-application-groups.component';
import { EnaApplicationDisplayTextGroupsComponent } from './ena-application-display-text-groups/ena-application-display-text-groups.component';

@Component({
  selector: 'ena-application',
  templateUrl: 'ena-application.component.html',
  styleUrls: ['ena-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    JsonPipe,
    CoreComponentsAngularModule,
    NgIf,
    ReactiveFormsModule,
    NgSelectModule,
    NgbCollapseModule,
    EnaApplicationErrorsComponent,
    AddressLookupV2Component,
    NgxSpinnerModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    GalleryV2LayoutComponent,
    NgbTooltipModule,
    EnaApplicationActionsComponent,
    EnaApplicationGroupsComponent,
    EnaApplicationDisplayTextGroupsComponent
  ],
  providers: [EnaApplicationErrorsPresenter, EnaApplicationAttachmentsPresenter],
  standalone: true
})
export class EnaApplicationComponent implements OnChanges, OnInit {
  vm$: BehaviorSubject<EnaFormVm> = new BehaviorSubject<EnaFormVm>(null);
  @Input() project: Project;
  @Input() readonly: boolean;
  @Input() tenant: string;
  @Input() customAttachments: CustomAttachment[];
  @Input() tabLayouts: DisplayTabLayout[];

  constructor(private presenter: EnaApplicationPresenter) {}

  ngOnInit(): void {
    this.presenter.loadAttachments(this.project.attachments, this.project.id, this.tenant, this.readonly);
    this.presenter.load(this.vm$, this.project, this.tabLayouts);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (Object.keys(changes).includes('project') && !changes.project.firstChange) {
      await this.presenter.hydrateProject({ ...changes.project.currentValue });
      this.presenter.loadAttachments(
        changes.project.currentValue['attachments'],
        this.project.id,
        this.tenant,
        this.readonly
      );
    }
  }

  async retryFormLoad(): Promise<void> {
    await this.presenter.retryFormLoad();
  }
}
