import { IAddressV2 } from './domain/address-v2';
import { AddressProvider } from './domain/address-provider';

export interface AddressLookupV2Vm {
  enterManually: string;
  countryCodes: string[];
  label: string;
  provider: AddressProvider;
  address?: IAddressV2 | null;
}

export enum ControlContext {
  Mobile = 'mobile',
  Desktop = 'desktop'
}
