import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Project } from '../../core/domain/project';
import { ProjectAttachment } from '../project-attachments/project-attachments.component';

// VM
export interface EnaFormVm {
  project: Project;
  groups: ApplicationFormGroupWithHooks[];
  displayTextGroups: DisplayTextGroupWithHooks[];
  i18nTitle: string;
  i18nSubmitLabel: string;
  i18nSaveLabel: string;
  i18nRetryLabel: string;
  i18nManuallyApproveLabel: string;
  i18nUndoLabel: string;
  i18nRetryMessage: string;
  i18nSelectDateMessage: string;
  i18nAddDeviceLabel: string;
  i18nRemoveDeviceLabel: string;
  i18nDuplicateDeviceLabel: string;
  i18nManualEntryDeviceLabel: string;
  i18nManuallyApprovedLabel: string;
  i18nSearchDeviceLabel: string;
  i18nDeviceIdLabel: string;
  i18nDeviceTypeLabel: string;
  i18nManufacturerLabel: string;
  i18nModelLabel: string;
  i18nPhaseLabel: string;
  i18nExternalDnoLabel: string;
  i18nRefNoLabel: string;
  status: string;
  externalStatus: string | null;
  externalDno: string | null;
  externalApplicationId: string | null;
  showRetry: boolean;
  showLoader: boolean;
  showRejectedStatus: boolean;
  showAddDevice: boolean;
  disableSave: boolean;
  saveInProgress: boolean;
  approveInProgress: boolean;
  submitInProgress: boolean;
  inputsDisabled: boolean;

  // qa hooks
  qaEnaApplicationStatus: string;
  qaEnaApplicationExternalStatus: string;
  qaEnaApplicationExternalDno: string;
  qaEnaApplicationRefNo: string;
}

export interface EnaActionsVm {
  disableSave: boolean;
  saveInProgress: boolean;
  submitInProgress: boolean;
  approveInProgress: boolean;
  undoLabel: string;
  saveLabel: string;
  submitLabel: string;
  hasActions: boolean;
  isInReview: boolean;
  manuallyApproveLabel: string;
  qaUndoButton: string;
  qaSaveButton: string;
  qaSubmitButton: string;
  qaManuallyApprove: string;
}

export interface EnaError {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

export interface EnaAttachmentsVm {
  attachments: ExtendedEnaAttachment[];
  form: FormGroup;
  selectionDisabled: boolean;
  uploadProgress: FileUploadProgress;
  uploadInProgress: boolean;
  acceptedFileTypes: string;
  canUploadFiles: boolean;
  i18nFileNameLabel: string;
  i18nUploadDateLabel: string;
  i18nUploadedByLabel: string;
  i18nUploadLabel: string;
  i18nActionsLabel: string;
  i18nUnknownLabel: string;
  i18nDownloadBtnLabel: string;
  i18nDeleteBtnLabel: string;
  i18nProjectSpecificAttachmentsLabel: string;
  qaUploadAttachmentBtn: string;
}

export interface ExtendedEnaAttachment extends EnaAttachment {
  qaEnaAttachmentCheckbox: string;
  qaEnaDownloadAttachmentBtn: string;
}

// DM
export interface EnaFormDm {
  formGroups: ApplicationFormGroup[];
  displayTextGroups: DisplayTextGroup[];
  applicationStatus: string;
  externalStatus: string;
  manualApplicationTransition?: ManualApplicationTransition[];
  externalApplicationId?: string;
  externalDno?: string;
  applicationId: string;
  i18ns: EnaFormI18n;
  showRetry: boolean;
  enableSave: boolean;
  saveInProgress: boolean;
  approveInProgress: boolean;
  submitInProgress: boolean;
  loading: boolean;
  project: Project;
}

export interface ManualApplicationTransition {
  transitionTo: DnoStatus;
  updatedBy: { id: string; label: string } | null;
  updatedDate: string;
}

export interface EnaActionsDm {
  disableSave: boolean;
  isInReview: boolean;
  saveInProgress: boolean;
  approveInProgress: boolean;
  submitInProgress: boolean;
  i18nSubmitLabel: string;
  i18nResubmitLabel: string;
  i18nSaveLabel: string;
  i18nManuallyApproveLabel: string;
  i18nUndoLabel: string;
  hasActions: boolean;
  applicationStatus: string;
}

export interface EnaAttachmentsDm {
  attachments: EnaAttachment[];
  form: FormGroup;
  i18ns: EnaFormI18n;
  uploadProgress: FileUploadProgress;
  canEditFile: boolean;
  canUploadFile: boolean;
  acceptedFileTypes: string;
  selectionDisabled: boolean;
}

export interface FileUploadProgress {
  fileName: string;
  label?: string;
  progress: number;
  roundedProgress: string;
  barWidth?: string;
  barHeight?: string;
  ariaValue?: string;
  isComplete?: boolean;
}

export interface ApplicationFormGroup {
  title: string;
  key: string;
  subHeading?: string;
  sections: ApplicationFormSection[];
  items?: ApplicationFormItem[];
  form: FormGroup;
  isOpen: boolean;
  toggleClass: string;
  useHug: boolean;
  showSavedState?: boolean;
  savedState?: string;
  savedStateCss?: string;
  invalidGroupMessage?: string;
}

export interface DisplayTextGroup {
  title: string;
  timestamp: string;
  isOpen: boolean;
  emptyEntriesString?: string;
  headerString?: string;
  entries: {
    key: string;
    value: string;
  }[];
}

export interface DisplayTextGroupWithHooks extends DisplayTextGroup {
  displayEmptyText: boolean;
  isOpen: boolean;
  toggleClass: string;
  toggleColor: string;
  formattedTimestamp: string;
  // qa hooks
  qaEnaDisplayTextGroupName: string;
}

export interface ApplicationFormGroupWithHooks extends ApplicationFormGroup {
  items?: ApplicationFormItemWithHooks[];
  numberOfItems?: string | null;
  sections: ApplicationFormSectionWithHooks[];
  // qa hooks
  qaEnaApplicationGroupName: string;
  qaEnaApplicationGroupStatus: string;
  qaEnaApplicationAddDeviceBtn: string;
  qaEnaApplicationNumberOfItems: string;
}

export interface ApplicationFormItem {
  name: string;
  id?: string;
  isNew?: boolean;
  manualEntry?: boolean;
  key: string;
  sections: ApplicationFormSection[];
}

export interface ApplicationFormItemWithHooks extends ApplicationFormItem {
  sections: ApplicationFormSectionWithHooks[];
  removeDisabled: boolean;
  isLastItem: boolean;

  // qa hooks
  qaEnaItemManualEntry: string;
  qaEnaItemBackToDeviceSearch: string;
  qaEnaItemDuplicateDevice: string;
  qaEnaItemRemoveDevice: string;
}

export interface ApplicationFormSection {
  name?: string;
  id?: string;
  controls: ApplicationFormControl[];
}

export interface ApplicationFormSectionWithHooks extends ApplicationFormSection {
  controls: ApplicationFormControlWithHooks[];
  // qa hooks
  qaEnaApplicationSectionHeader: string;
  hidden: boolean;
}

export interface ApplicationFormControl {
  name: string;
  label: string;
  type: string;
  value: any;
  validators: string[];
  validationMessages?: ValidationMessages;
  hasRegex?: boolean;
  regex?: string;
  regexMessage?: string;
  tooltip?: string;
  typeToSearchLabel?: string;
  options?: string[] | any[];
  options$?: Observable<RegisteredDeviceData[]>;
  optionInput$?: Subject<string>;
  optionLoading$?: Subject<boolean>;
  optionSelectedData?: RegisteredDeviceData;
  isRequired?: boolean;
  displayIf?: DisplayIf;
  data?: any;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
}

export interface RegisteredDeviceData {
  deviceClass: string;
  deviceType: string;
  displayName: string;
  externalDeviceId: string;
  manufacturerName: string;
  modelName: string;
  phase: string;
}

export interface ApplicationFormControlWithHooks extends ApplicationFormControl {
  // qa hooks
  qaEnaApplicationFormFieldLabel: string;
  qaEnaApplicationFormFieldInput: string;
  qaFormFieldString: string;
  qaEnaApplicationFormFieldError: string;
  qaEnaSelectedDeviceSearchOption: string;
}

export interface ValidationMessages {
  [key: string]: string;
}

export interface DisplayIf {
  property: string;
  value: string | boolean;
}

// DTO
export interface EnaFormDto {
  groups: ApplicationFormGroupDto[];
  displayTextGroups?: ApplicationDisplayTextGroupDto[];
  application: any;
  applicationStatus: string;
}

export interface EnaSubmittedApplicationDto {
  externalApplicationId: string;
}

export interface ApplicationFormGroupDto {
  title: string;
  subHeading?: string;
  key: string;
  items?: ApplicationFormItem[];
  sections?: ApplicationFormSection[];
}

export interface ApplicationDisplayTextGroupDto {
  title: string;
  emptyDisplayTextString?: string;
  headerString?: string;
  displayText: {
    timestamp: string;
    detail: {
      key: string;
      value: string;
    }[];
  };
}

// UTILS

export enum GroupState {
  incomplete = 'Incomplete',
  complete = 'Complete'
}

export enum EnaErrorType {
  GetApplication = 'enaForm.errors.getApplication',
  PutApplication = 'enaForm.errors.putApplication',
  PostApplication = 'enaForm.errors.postApplication'
}

export interface EnaFormI18n {
  [key: string]: string;
}

export interface EnaAttachmentInputs {
  attachments: ProjectAttachment[];
  projectId: string;
  tenant: string;
  readonly: boolean;
}

export interface EnaAttachment extends ProjectAttachment {
  fileDisplayName: string;
  safeFormControlName: string;
  uploadedByDisplayName: string;
  uploadDateDisplay: string;
  showDownload: boolean;
}

export const DNO_MODULE_LD_FEATURE_KEY = 'module-dno';

export enum DnoStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_SENT = 'NOT SENT',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN REVIEW'
}

export const BLANK_DEVICE_ITEM: ApplicationFormItem = {
  name: 'deviceToInstall_0',
  key: 'devicesToInstall',
  manualEntry: false,
  sections: [
    {
      controls: [
        {
          name: 'deviceType',
          label: 'Device Type',
          type: 'select',
          options: [
            {
              label: 'EVCP_AC',
              value: 'EVCP_AC'
            }
          ],
          value: 'EVCP_AC',
          validators: ['required']
        }
      ]
    },
    {
      id: 'deviceToInstall_0_asyncDataLookup',
      controls: [
        {
          name: 'deviceSysRef',
          label: 'Device System Reference',
          validators: ['required'],
          type: 'asyncDataLookup',
          value: null
        }
      ]
    },
    {
      controls: [
        {
          name: 'targetInstallDate',
          label: 'Target Date of Installation',
          type: 'date',
          value: null,
          validators: ['required']
        },
        {
          name: 'powerFactor',
          label: 'Power Factor',
          type: 'number',
          value: null,
          validators: ['required'],
          min: 0,
          max: 1
        }
      ]
    },
    {
      id: 'deviceToInstall_0_manualSection',
      controls: [
        {
          name: 'deviceType',
          label: 'Device Type',
          type: 'select',
          options: [
            {
              label: 'EVCP_AC',
              value: 'EVCP_AC'
            }
          ],
          value: 'EVCP_AC',
          validators: ['required']
        }
      ]
    },
    {
      id: 'deviceToInstall_0_manualSection',
      controls: [
        {
          name: 'manufacturerName',
          label: 'Manufacturer',
          type: 'text',
          value: null,
          validators: ['required']
        },
        {
          name: 'deviceRef',
          label: 'Device Reference Number',
          type: 'text',
          value: null,
          validators: ['required'],
          tooltip: 'This should be a number/reference/model that uniquely identifies the device'
        },
        {
          name: 'targetInstallDate',
          label: 'Target Date of Installation',
          type: 'date',
          value: null,
          validators: ['required']
        },
        {
          name: 'powerFactor',
          label: 'Power Factor',
          type: 'number',
          value: null,
          validators: ['required'],
          min: 0,
          max: 1
        },
        {
          name: 'deviceMaxCurrentDemand',
          label: 'Device Max Current Demand',
          type: 'number',
          value: null,
          validators: ['required']
        },
        {
          name: 'phaseCode',
          label: 'Number of Phases',
          options: [
            {
              label: 'Single Phase',
              value: 'Single Phase'
            },
            {
              label: 'Three Phase',
              value: 'Three Phase'
            }
          ],
          type: 'select',
          value: '',
          validators: ['required']
        }
      ]
    }
  ]
};

export const DEVICE_MANUAL_CONTROLS = [
  'deviceType',
  'manufacturerName',
  'deviceRef',
  'targetInstallDate',
  'powerFactor',
  'deviceMaxCurrentDemand',
  'phaseCode'
];
export const DEVICE_SEARCH_CONTROLS = ['deviceType', 'deviceSysRef', 'targetInstallDate', 'powerFactor'];
