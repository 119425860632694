import { Injectable } from '@angular/core';
import {
  CustomerShippingAddress,
  MyOrdersDm,
  MyOrdersVm,
  ProductResource,
  SelectedAddressType,
  TenantShippingAddress
} from './my-orders.model';
import { MyOrdersRepository } from './my-orders.repository';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MyOrdersPresenter {
  constructor(private repository: MyOrdersRepository, private calendar: NgbCalendar) {}

  load({
    vmSubject$,
    products,
    orders,
    driverAddress,
    defaultAddress,
    projectId,
    canOrderProducts,
    minShipmentDays,
    refresh = false
  }: {
    vmSubject$: BehaviorSubject<MyOrdersVm>;
    products: ProductResource[];
    orders: any[];
    driverAddress?: CustomerShippingAddress;
    defaultAddress?: TenantShippingAddress;
    projectId: string;
    minShipmentDays?: number;
    refresh?: boolean;
    canOrderProducts: boolean;
  }): void {
    this.repository.load({
      cb: (dataDm: MyOrdersDm): void => {
        const viewModel: MyOrdersVm = {
          installationPackagesOptions: dataDm.installationPackagesOptions,
          packageAddons: dataDm.packageAddons,
          packageName: dataDm.packageName,
          productList: dataDm.products,
          accessoriesList: dataDm.accessories,

          hasPackage: !!dataDm.packageName,

          driverAddress: dataDm.address['driver'],
          defaultAddress: dataDm.address['default'],

          showCustomAddress: dataDm.orderForm.get('selectedAddressType').value === SelectedAddressType.custom,
          showDriverAddress: dataDm.orderForm.get('selectedAddressType').value === SelectedAddressType.driver,

          addressTypeCustom: SelectedAddressType.custom,
          addressTypeDriver: SelectedAddressType.driver,

          shipmentDatePlaceholder: dataDm.i18n.placeholder.shipmentDate,
          shipmentDateEarliest: dataDm.minShipmentDate,

          orderForm: dataDm.orderForm,

          numberOfOrders: dataDm.orders.length ?? 0,
          showOrders: dataDm.orders?.length > 0,

          isLoading: dataDm.isLoading,
          isProcessingOrder: dataDm.isProcessingOrder,

          showOrderForm: !dataDm.orders.length || dataDm.showOrderForm,

          isOrderingEnabled: driverAddress && defaultAddress && canOrderProducts,

          showStartReorderButton: dataDm.canReorder && !dataDm.showOrderForm,
          showStopReorderButton: dataDm.canReorder,
          minShipmentDays: minShipmentDays,
          refresh: dataDm.needsRefresh,

          ...this.qaHooks(),
          ...this.parseI18ns(dataDm)
        };
        vmSubject$.next(viewModel);
      },
      products,
      orders,
      driverAddress,
      defaultAddress,
      projectId,
      minShipmentDays,
      refresh
    });
  }

  submitOrder(): void {
    this.repository.createOrder().then();
  }

  startNewOrder(): void {
    this.repository.startNewOrder();
  }

  stopNewOrder(): void {
    this.repository.stopNewOrder();
  }

  cleanUp(): void {
    this.repository.cleanUp();
  }

  isDateDisabled(date): boolean {
    return [6, 7].includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day)));
  }

  qaHooks() {
    return {
      qaCreateOrderSection: 'moCreateOrderSection',
      qaOrdersSection: 'moOrdersSection',
      qaOrderForm: 'moOrderForm',
      qaOrderFormHardwareSection: 'moOrderFormHardwareSection',
      qaOrderFormAddressSection: 'moOrderFormAddressSection',
      qaPackageTitle: 'moPackageTitle',
      qaCreateOrderButton: 'moCreateOrderButton',
      qaAddressTypeControl: 'moAddressTypeControl',
      qaStartReorderButton: 'moStartReorderButton',
      qaStopReorderButton: 'moStopReorderButton',

      qaCustomAddress_name: 'moCustomAddress--name',
      qaCustomAddress_company: 'moCustomAddress--company',
      qaCustomAddress_email: 'moCustomAddress--email',
      qaCustomAddress_phone: 'moCustomAddress--phone',
      qaCustomAddress_line1: 'moCustomAddress--line1',
      qaCustomAddress_line2: 'moCustomAddress--line2',
      qaCustomAddress_locality: 'moCustomAddress--locality',
      qaCustomAddress_town: 'moCustomAddress--town',
      qaCustomAddress_county: 'moCustomAddress--county',
      qaCustomAddress_country: 'moCustomAddress--country',
      qaCustomAddress_postCode: 'moCustomAddress--postCode',

      qaDriverAddress_name: 'moDriverAddress--name',
      qaDriverAddress_company: 'moDriverAddress--company',
      qaDriverAddress_email: 'moDriverAddress--email',
      qaDriverAddress_phone: 'moDriverAddress--phone',
      qaDriverAddress_line1: 'moDriverAddress--line1',
      qaDriverAddress_line2: 'moDriverAddress--line2',
      qaDriverAddress_locality: 'moDriverAddress--locality',
      qaDriverAddress_town: 'moDriverAddress--town',
      qaDriverAddress_county: 'moDriverAddress--county',
      qaDriverAddress_country: 'moDriverAddress--country',
      qaDriverAddress_postCode: 'moDriverAddress--postCode'
    };
  }

  parseI18ns(dm: MyOrdersDm) {
    return {
      titleCreateOrder: dm.i18n.title.createOrder,
      titleShipping: dm.i18n.title.shipping,
      titleOrders: dm.i18n.title.orders,
      titleContactDetails: dm.i18n.title.contactDetails,
      titleAddress: dm.i18n.title.address,

      messageOrdersEmpty: dm.i18n.message.ordersEmpty,

      validationOptional: `(${dm.i18n.validation.optional})`,
      validationRequired: dm.i18n.validation.required,

      validationWeekday: dm.i18n.validation.weekday,
      validationMinShipDate: dm.i18n.validation.minShipDate,
      validationInvalidDate: dm.i18n.validation.invalidDate,
      validationInvalidEmail: dm.i18n.validation.invalidEmail,
      validationCountryCode: dm.i18n.validation.countryCode,

      placeholderSelectPackages: dm.installationPackagesOptions
        ? dm.i18n.placeholder.selectProducts
        : dm.i18n.placeholder.noInstallationPackages,
      placeholderSelectProducts: dm.i18n.placeholder.selectProducts,
      placeholderSelectAccessories: dm.i18n.placeholder.selectAccessories,
      placeholderSelectAddons: dm.i18n.placeholder.selectAddons,

      labelPackage: `${dm.i18n.label.package}:`,
      labelProducts: dm.i18n.label.products,
      labelAccessories: dm.i18n.label.accessories,
      labelPreferredShipmentDate: dm.i18n.label.preferredShipmentDate,
      labelCustomAddress: dm.i18n.label.customAddress,
      labelDriverAddress: dm.i18n.label.driverAddress,
      labelName: dm.i18n.label.name,
      labelPhone: dm.i18n.label.phone,
      labelEmail: dm.i18n.label.email,
      labelCompany: dm.i18n.label.company,
      labelLine1: dm.i18n.label.line1,
      labelLine2: dm.i18n.label.line2,
      labelTown: dm.i18n.label.town,
      labelLocality: dm.i18n.label.locality,
      labelCounty: dm.i18n.label.county,
      labelCountry: dm.i18n.label.country,
      labelPostcode: dm.i18n.label.postcode,
      labelPackageAddons: dm.i18n.label.packageAddons,
      labelSelectPackage: dm.i18n.label.selectPackage,

      alertTitle: dm.i18n.alert.title,
      alertMessage1: dm.i18n.alert.message1,
      alertMessage2: dm.i18n.alert.message2,
      buttonOrderHardware: dm.i18n.button.orderHardware,
      buttonStartReorder: dm.i18n.button.startReorder,
      buttonStopReorder: dm.i18n.button.stopReorder
    };
  }
}
