import { Component, Input } from '@angular/core';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { EnaFormVm } from '../ena.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { EnaApplicationPresenter } from '../ena-application.presenter';

@Component({
  selector: 'ena-application-display-text-groups',
  standalone: true,
  imports: [JumptechCardsLibModule, CoreComponentsAngularModule],
  templateUrl: 'ena-application-display-text-groups.component.html',
  styleUrl: 'ena-application-display-text-groups.component.scss'
})
export class EnaApplicationDisplayTextGroupsComponent {
  @Input() vm: EnaFormVm;

  constructor(private presenter: EnaApplicationPresenter) {}

  toggleDisplayTextGroup(groupTitle: string) {
    this.presenter.toggleDisplayTextGroup(groupTitle);
  }
}
