import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges
} from '@angular/core';
import { AbstractLayout } from './abstract.layout';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Project } from '../domain/project';
import { LayoutUpdateService } from '../layout-update.service';
import { ProjectCardLayout } from '../domain/project-card-layout';
import { CardControlService } from '../card-control.service';
import { ProjectUpdateService } from '../project-update.service';
import { debounceTime, firstValueFrom, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { CardLayoutItem } from '../domain/card-layout-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { showCardItem } from '../utils/filter';
import { AccessService } from '../../auth/services/access.service';
import { HttpImageComponent, I18nKeys, QuestionBase } from '@jump-tech-frontend/cards';
import { CardsLibTranslationService } from '../cards-lib-translation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoModule } from '@ngneat/transloco';
import { ListViewComponent, VideoPlayerComponent } from '@jump-tech-frontend/question-components';
import { EditLayoutQuestionsComponent } from './edit-layout-questions.component';
import { ChargePointLocationMapComponent } from './charge-point-location-map.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'app-list-layout',
  templateUrl: './list-layout.component.html',
  styleUrls: ['../../project-detail/project-detail.component.scss'],
  standalone: true,
  imports: [
    NgxLoadingModule,
    NgIf,
    CoreComponentsAngularModule,
    NgFor,
    ChargePointLocationMapComponent,
    EditLayoutQuestionsComponent,
    VideoPlayerComponent,
    TranslocoModule,
    HttpImageComponent,
    ReadOnlyDisableDirective,
    ListViewComponent
  ],
  providers: [LayoutUpdateService]
})
export class ListLayoutComponent extends AbstractLayout implements OnInit, OnChanges, OnDestroy {
  private _readOnly: boolean;
  private _data: any = {};
  private _unsubscribeFormChanges$ = new Subject();
  private _originalData: any;
  get readOnly(): boolean {
    return this._readOnly;
  }
  @Input() set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
  }
  @Input() project: Project;
  @Input() layout: ProjectCardLayout;
  @Input() set data(data: any) {
    this._data = data;
  }
  @Input() okAction?: string;
  @Input() i18ns: I18nKeys;
  @Output() editMode = new EventEmitter();

  @Input() showSave = true;
  @Output() formUpdated = new EventEmitter();
  @Output() saved = new EventEmitter<any>();

  private destroyRef: DestroyRef = inject(DestroyRef);

  form = signal<UntypedFormGroup>(null);
  layoutItems: CardLayoutItem[] = [];
  loading = false;
  galleryImages = [];
  projectUpdateServiceSubscription: Subscription;
  // TODO: Replace with a separate explicit "HTML safe" component
  htmlInputSupportedCardKeys = [
    'quoteDetails',
    'quoteSummary',
    'dnoDemandSummary',
    'fullContractValueDetails',
    'depositDetails',
    'finalPaymentDetails',
    'sip'
  ];
  public staticMapUrl = signal(null);

  constructor(
    sanitizer: DomSanitizer,
    apiService: ApiService,
    featureAccessService: AccessService,
    modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private layoutUpdateService: LayoutUpdateService,
    private projectUpdateService: ProjectUpdateService,
    private cardControlService: CardControlService,
    private cardsLibI18nService: CardsLibTranslationService
  ) {
    super(sanitizer, apiService, featureAccessService, modalService);
    this.i18ns = this.cardsLibI18nService.loadTranslations();
  }

  async ngOnInit() {
    this.layout.showEdit = true;
    this.layoutUpdateService.layoutUpdates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(dataSource => {
      if (dataSource === this.layout.dataSource) {
        this.loading = true;
      }
      if (dataSource === LayoutUpdateService.CANCEL) {
        this.loading = false;
      }
    });
    this.projectUpdateServiceSubscription = this.projectUpdateService.projectUpdates
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((project: Project) => {
        if (project.id === this.project.id) {
          this.initialiseLayoutItems();
        }
      });
    this.form.set(await this.cardControlService.toFormGroup(this.layoutItems, this._data));
    if (this.formUpdated) {
      this.form()
        .valueChanges.pipe(debounceTime(1000), takeUntil(this._unsubscribeFormChanges$))
        .subscribe(value => {
          this.formUpdated.emit({ ...value, formStatus: this.form().status });
        });
    }
    await this.renderReadOnlyMap();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (Object.prototype.hasOwnProperty.call(changes, 'layout')) {
      this.initialiseLayoutItems();
    }

    if (Object.prototype.hasOwnProperty.call(changes, 'data')) {
      this._originalData = JSON.parse(JSON.stringify(this._data));
      const current = JSON.stringify(changes?.data?.currentValue || {});
      const previous = JSON.stringify(changes?.data?.previousValue || {});
      if (this.form() && current.localeCompare(previous) !== 0) {
        await this.cardControlService.patchValues(this.layoutItems, this._data, this.form());
      }
    }
  }

  ngOnDestroy() {
    this.projectUpdateServiceSubscription.unsubscribe();
    this._unsubscribeFormChanges$.next(null);
    this._unsubscribeFormChanges$.complete();
  }

  initialiseLayoutItems() {
    const items = JSON.parse(JSON.stringify(this.layout.items));
    this.layoutItems = Object.create(Object.assign([], items));
    this.layoutItems.map(item => {
      // Only put the content in if there's no src
      if (item.src) {
        item.src = this.toSafeUrl(item.src);
        delete item.content;
      } else if (
        item.editConfig?.controlType === 'ButtonGroupQuestion' &&
        item.editConfig?.options?.length &&
        Array.isArray(item.editConfig.options)
      ) {
        const value = this._data[item.editConfig.key];
        const option = item.editConfig.options.find(opt => opt.key === value);
        let displayValue = option?.value ?? value ?? '';
        if (option?.usei18n) {
          displayValue = this.i18ns[option.value];
        }
        item.content = this.replaceTemplateVariablesInContent(displayValue);
      } else if (
        item.editConfig?.controlType !== 'MultiDropdownQuestion' &&
        item.editConfig?.options?.length &&
        Array.isArray(item.editConfig.options)
      ) {
        const value = this._data[item.editConfig.key];
        const displayValue = item.editConfig.options.find(opt => opt.key === value)?.value ?? value ?? '';
        item.content = this.replaceTemplateVariablesInContent(displayValue);
      } else if (item.content) {
        item.content = this.replaceTemplateVariablesInContent(item.content);
      }
      return item;
    });
  }

  showGalleryForImage(item, layout, index = 0) {
    const key = item.editConfig?.key;
    this.openImagesModal(layout.data, `${key}_${index}`);
  }

  isImageSet(item) {
    return !!item.src;
  }

  isImageArray(item) {
    return item.src && Array.isArray(item.src) && !item.src?.[0]?.includes('mp4');
  }

  isVideoArray(item) {
    return item.src && Array.isArray(item.src) && item.src?.[0]?.includes('mp4');
  }

  isListView(item) {
    return item?.editConfig?.controlType === 'ListView';
  }

  isSingleImage(item) {
    return item.src && !Array.isArray(item.src) && item.editConfig;
  }

  // i.e. DNO manufacturer image. No link to gallery.
  isStaticImage(item) {
    return item.src && !Array.isArray(item.src);
  }

  getLabel(label) {
    return label.endsWith(':') || label.endsWith('?') ? label : label + ':';
  }

  async setForm() {
    this.layout.edit = true;
    this.form.set(await this.cardControlService.toFormGroup(this.layoutItems, this._data));
    this.editMode.emit(true);
  }

  cancelEdit() {
    this._data = this._originalData;
    this.layout.edit = false;
    this.editMode.emit(false);
  }

  save(form: UntypedFormGroup) {
    this.editMode.emit(false);
    const formValue = this.cardControlService.dataFromForm(form);
    if (Object.keys(formValue).length) {
      // Only save if there's actually a change
      this.spinnerService.show().catch(console.log);
      this.apiService
        .updateProject(this.project, { data: formValue }, this.layout.updateResource, this.layout.dataSource)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async () => {
          if (this.layout.postUpdateAction) {
            await lastValueFrom(this.apiService.projectAction(this.project.id, this.layout.postUpdateAction, {}));
          }
          Object.assign(this.project.data, formValue);
          Object.assign(this._data, formValue);
          if (this.saved) {
            this.saved.emit(formValue);
          }
          await this.renderReadOnlyMap();
          this.initialiseLayoutItems();
          await this.spinnerService.hide();
        });
    }
    this.layout.edit = false;
  }

  get isReadOnly() {
    return (
      this.readOnly || !this.layoutUpdateService.allowEdit(this.layout) || !this.layout.showEdit || !!this.layout.edit
    );
  }

  replaceTemplateVariablesInContent(content) {
    return this.replaceTemplateVariables(content, this._data);
  }

  toSanitisedUrl(value) {
    const src = this.getImageSrc(value);
    return src;
  }

  toSafeUrl(content) {
    const value = this.replaceTemplateVariables(content, this._data, true);
    if (value) {
      if (Array.isArray(value)) {
        return value.map(v => {
          return this.toSanitisedUrl(v);
        });
      }
      return this.toSanitisedUrl(value);
    }
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  showCardItem(item: any): boolean {
    return showCardItem(item, { ...this.project.data, ...this._data });
  }

  asQuestionBase(param: { key: string }) {
    return param as QuestionBase<string>;
  }

  async renderReadOnlyMap(): Promise<void> {
    const shouldRender =
      this.form() &&
      !this.layout.edit &&
      this.layout?.image?.key &&
      this._data[this.layout.image.key] &&
      this.layout.image.position === 'bottom';
    if (shouldRender) {
      const url = this.getSrc(this.layout.image);
      const staticImage = await firstValueFrom(this.apiService.getSignedMapsUrl(url));
      this.staticMapUrl.set(staticImage);
    }
  }

  getOriginalUrl(src: string | string[]) {
    const replacer = (value: string) => value.replace(/size=s/, '');
    return Array.isArray(src) ? src.map(replacer) : replacer(src);
  }

  getSrc(image) {
    const markers = this._data[image.key];

    if (markers && Array.isArray(markers) && markers.length) {
      const markerLocations = markers.map(x => `${x.lat},${x.lng}`).join('%7C');

      // We also want to replace the address.lat/long with the first
      // marker so we're sure to show it
      const src = this.replaceTemplateVariables(image.src, {
        ...this._data,
        address: { latitude: markers[0].lat, longitude: markers[0].lng }
      });

      return `${src}&markers=color:blue%7C${markerLocations}`;
    }

    return this.replaceTemplateVariables(image.src, this._data);
  }
}
