import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

import { Project } from '../../core/domain/project';
import { ProjectStatusChangeVm } from './project-status-change.model';
import { ProjectStatusChangePresenter } from './project-status-change.presenter';
import { ProjectState } from '../../core/domain/project-configuration';

@Component({
  selector: 'project-status-change',
  templateUrl: './project-status-change.component.html',
  styleUrl: './project-status-change-component.scss',
  standalone: true,
  imports: [CoreComponentsAngularModule, TranslocoModule, AsyncPipe, NgSelectModule, FormsModule],
  providers: [ProjectStatusChangePresenter]
})
export class ProjectStatusChangeComponent implements OnInit, OnChanges {
  vm$: BehaviorSubject<ProjectStatusChangeVm> = new BehaviorSubject<ProjectStatusChangeVm>(null);

  @Input() project: Project;
  @Input() projectStates: ProjectState[] = [];

  constructor(private presenter: ProjectStatusChangePresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$, this.project, this.projectStates);
  }

  ngOnChanges(): void {
    this.presenter.load(this.vm$, this.project, this.projectStates);
  }

  async changeProjectStatus(status: ProjectState | undefined): Promise<void> {
    await this.presenter.changeProjectStatus(status);
  }
}
