<div class="schedule-event-selected-job-details__header">
  <div class="schedule-event-selected-job-details__heading">{{ vm.jobType }}</div>
  <div class="schedule-event-selected-job-details__row-split">
    <div class="schedule-event-selected-job-details__row-item">
      <span class="schedule-event-selected-job-details__icon">
        <jui-icon name="house" color="secondary" size="sm"></jui-icon>
      </span>
      <span>{{ vm.addressDisplay }}</span>
    </div>
    @if (vm.customerName) {
      <div class="schedule-event-selected-job-details__row-item--stacked">
        <div class="schedule-event-selected-job-details__row-item-col">
          <span class="schedule-event-selected-job-details__icon">
          <jui-icon name="person" color="secondary" size="sm"></jui-icon>
        </span>
          <span>{{ vm.customerName }}</span>
        </div>
        <div class="schedule-event-selected-job-details__row-item-col">
          <span class="schedule-event-selected-job-details__icon">
          <jui-icon name="email" color="secondary" size="sm"></jui-icon>
        </span>
          <span>{{ vm.email }}</span>
        </div>
      </div>
    } @else {
      <div class="schedule-event-selected-job-details__row-item">
        <span class="schedule-event-selected-job-details__icon">
          <jui-icon name="email" color="secondary" size="sm"></jui-icon>
        </span>
        <span>{{ vm.email }}</span>
      </div>
    }
    <div class="schedule-event-selected-job-details__row-item">
      <span class="schedule-event-selected-job-details__icon">
        <jui-icon name="phone" color="secondary" size="sm"></jui-icon>
      </span>
      <span>{{ vm.phoneNumber }}</span>
    </div>
  </div>
  <div class="schedule-event-selected-job-details__divider"></div>
</div>
