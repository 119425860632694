<div class="schedule-event-selected-job-details__actions">
  <jui-icon
    *ngIf="!vm.hideCloseIcon"
    class="schedule-event-selected-job-details__close"
    name="close"
    color="current"
    size="xs"
    (click)="close.emit()"
    [attr.data-qa]="'selectedJobDetailsClose'"
  ></jui-icon>
  <jui-button color="secondary" display="ghost" size="sm" (click)="goToProject.emit()" [attr.data-qa]="'goToProjectBtn'">{{vm.i18nGoToProjectBtn}}</jui-button>
  @if (vm.showCheckOverlapsButton) {
    <jui-button color="secondary" size="sm" (click)="this.checkCollisions.emit()" [loading]="vm.showOverlapsLoader" [attr.data-qa]="'checkOverlapsBtn'">{{vm.i18nCheckOverlapsBtn}}</jui-button>
  }
  <jui-button
    size="sm"
    [color]="vm.scheduleActionColor"
    [loading]="vm.showScheduleLoader"
    [disabled]="vm.isScheduleButtonDisabled"
    (click)="scheduleNow.emit(vm.overlapsDetected)"
    [attr.data-qa]="'scheduleNowBtn'"
    >{{ vm.i18nScheduleNowBtn }}</jui-button
  >
  <jui-icon name="chevron_right" color="current" size="sm" (click)="hide.emit()" [attr.data-qa]="'selectedJobDetailsHide'"></jui-icon>
</div>
