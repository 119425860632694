import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { JumptechDate, JumptechDateSettings } from '@jump-tech-frontend/domain';
import { Job, JobAssignment } from '../../../../core/domain/job';
import {
  AssignedTradesPeopleVm,
  DateChangeType,
  DateTimeChangeEvent,
  RemoveTradespersonSlotEvent,
  RescheduleReasonVm,
  SelectedJobActionsVm,
  SelectedJobDetailsDm,
  SelectedJobDetailsToggleDm,
  SelectedJobDetailsToggleVm,
  SelectedJobDetailsVm,
  SelectedJobInfoVm,
  SelectedJobTimeVm,
  TradesPerson,
  TradesPersonOverlap,
  TradesPersonVm
} from '../../schedule.model';
import { ScheduleRepositoryV3 } from '../../schedule.repository.v3';
import { TIME_SLOTS } from '../../utils/schedule-constants';
import { ScheduleJobsDisplayPresenter } from '../schedule-jobs-display/schedule-jobs-display.presenter';
import { BehaviorSubject } from 'rxjs';
import { DropDownElement } from '../../../../shared/form-components/multiple-selection-dropdown.component';

@Injectable()
export class ScheduleEventSelectedJobDetailsPresenter {
  context = null;
  viewModel: SelectedJobDetailsVm;

  constructor(private repository: ScheduleRepositoryV3, private sjdPresenter: ScheduleJobsDisplayPresenter) {}

  public load(
    viewModelSubject$: BehaviorSubject<SelectedJobDetailsVm>,
    closedOnSuccessEventEmitter: EventEmitter<any>,
    ctx
  ) {
    this.context = ctx;
    this.repository.loadSelectedJobDetails((dm: SelectedJobDetailsDm) => {
      let vm: SelectedJobDetailsVm = null;
      if (dm && !dm.scheduleSuccess) {
        const fullJobForm = dm.form.get('fullJob') as FormGroup;
        const startIso = fullJobForm.get('startIso').value;
        const endIso = fullJobForm.get('endIso').value;
        const durationDisplay = this.calculateDurationDisplay(startIso, endIso, dm);
        const addressDisplay = this.formatAddress(dm.address);
        const isTimeInvalid = this.isTimeInvalid(fullJobForm);
        const i18ns = this.parseI18ns(dm);
        const qaHooks = this.parseQaHooks();
        const timeslots = this.localiseTimeSlots();
        const timeZoneLabel = i18ns.i18nTradesPeopleSubHeaderTimeZone.replace(
          '%tz%',
          JumptechDateSettings.defaultTimeZone
        );

        // build sub VM's
        const tradesPeople: TradesPersonVm[] = dm.tradesPeople.map((tp: TradesPerson) => {
          const isLead = tp.assignmentType === 'LEAD';
          return {
            ...tp,
            slots: tp.slots.map((slot, i) => {
              const slotForm = (dm.form.get(tp.assignedTo).get('slots') as FormArray).at(i) as FormGroup;
              const isTimeInvalid = this.isTimeInvalid(slotForm);
              const isTimeOutsideJob = this.isSlotTimeInvalid(dm.form, slotForm);
              const overlapDetails = [];
              for (const overlap of tp.overlaps) {
                if (overlap.overlappingSlotIndexes.includes(i)) {
                  overlapDetails.push(this.generateOverlapText(overlap, i18ns));
                }
              }
              return {
                ...slot,
                overlapDetails,
                form: dm.form,
                isTimeInvalid,
                isTimeOutsideJob,
                hasConstraintError: isTimeInvalid || isTimeOutsideJob,
                timeslots,
                readonly: dm.readonly,
                actionsDisabled: dm.overlapCheckInProgress || dm.readonly,
                i18nEndDatePlaceholder: i18ns.i18nEndDatePlaceholder,
                i18nRemoveTradespersonBtn: i18ns.i18nRemoveTradespersonBtn,
                i18nStartDatePlaceholder: i18ns.i18nStartDatePlaceholder,
                i18nEndDateBeforeStart: i18ns.i18nEndDateBeforeStart,
                i18nEndDateRequired: i18ns.i18nEndDateRequired,
                i18nInvalidDateFormat: i18ns.i18nInvalidDateFormat,
                i18nStartDateAfterEnd: i18ns.i18nStartDateAfterEnd,
                i18nStartDateRequired: i18ns.i18nStartDateRequired,
                i18nTimeIsInvalid: i18ns.i18nTimeIsInvalid,
                i18nTimeNotWithinJob: i18ns.i18nTimeNotWithinJob,
                i18nOverlapLabel: i18ns.i18nOverlapLabel,
                qaStartDateInput: qaHooks.qaStartDateInput,
                qaStartDateBtn: qaHooks.qaStartDateBtn,
                qaStartTimeSelect: qaHooks.qaStartTimeSelect,
                qaEndDateInput: qaHooks.qaEndDateInput,
                qaEndDateBtn: qaHooks.qaEndDateBtn,
                qaEndTimeSelect: qaHooks.qaEndTimeSelect,
                qaRemoveEngineerBtn: qaHooks.qaRemoveEngineerBtn
              };
            }),
            assignmentType: isLead ? 'LEAD' : 'SUPPORT',
            type: isLead ? 'filled' : 'outlined',
            color: isLead ? 'primary' : 'high',
            isSupportEngineer: !isLead,
            isLeadTradesperson: isLead,
            titleText: !isLead ? dm.i18nSetLeadEngineerBtn : '',
            i18nLead: dm.i18nLead,
            i18nRemoveTradespersonBtn: dm.i18nRemoveTradespersonBtn,
            i18nOverlapLabel: dm.i18nOverlapLabel,
            qaRemoveEngineerBtn: 'mdRemoveEngineerBtn',
            qaSetLeadEngineerBtn: 'mdSetLeadEngineerBtn',
            removeDisabled: dm.tradesPeople.length === 1 || dm.overlapCheckInProgress,
            readonly: dm.readonly,
            hasOverlap: tp.overlaps.length !== 0,
            overlapCheckInProgress: dm.overlapCheckInProgress,
            overlapDetails: !tp.slots.length ? tp.overlaps.map(overlap => this.generateOverlapText(overlap, i18ns)) : []
          };
        });

        const mainJobTime: SelectedJobTimeVm = {
          timeslots,
          form: dm.form.get('fullJob') as FormGroup,
          isTimeInvalid,
          durationDisplay,
          readonly: dm.readonly,
          timeZoneLabel,
          i18nEndDatePlaceholder: i18ns.i18nEndDatePlaceholder,
          i18nRemoveTradespersonBtn: i18ns.i18nRemoveTradespersonBtn,
          i18nStartDatePlaceholder: i18ns.i18nStartDatePlaceholder,
          i18nEndDateBeforeStart: i18ns.i18nEndDateBeforeStart,
          i18nEndDateRequired: i18ns.i18nEndDateRequired,
          i18nInvalidDateFormat: i18ns.i18nInvalidDateFormat,
          i18nStartDateAfterEnd: i18ns.i18nStartDateAfterEnd,
          i18nStartDateRequired: i18ns.i18nStartDateRequired,
          i18nTradesPeopleHeader: i18ns.i18nTradesPeopleHeader,
          i18nTradesPeopleSubHeader: i18ns.i18nTradesPeopleSubHeader,
          i18nTradesPeopleSubHeaderTimeZone: i18ns.i18nTradesPeopleSubHeaderTimeZone,
          i18nStartDateLabel: i18ns.i18nStartDateLabel,
          i18nStartTimeLabel: i18ns.i18nStartTimeLabel,
          i18nEndDateLabel: i18ns.i18nEndDateLabel,
          i18nEndTimeLabel: i18ns.i18nEndTimeLabel,
          i18nTimeIsInvalid: i18ns.i18nTimeIsInvalid,
          qaStartDateInput: qaHooks.qaStartDateInput,
          qaStartDateBtn: qaHooks.qaStartDateBtn,
          qaStartTimeSelect: qaHooks.qaStartTimeSelect,
          qaEndDateInput: qaHooks.qaEndDateInput,
          qaEndDateBtn: qaHooks.qaEndDateBtn,
          qaEndTimeSelect: qaHooks.qaEndTimeSelect,
          qaRemoveEngineerBtn: qaHooks.qaRemoveEngineerBtn,
          qaTradesPeopleSubHeaderTimeZone: qaHooks.qaTradesPeopleSubHeaderTimeZone
        };

        const rescheduleReason: RescheduleReasonVm = {
          form: dm.form,
          show: !dm.readonly && !dm.isInitialSchedule,
          i18nRescheduleReasonInputLabel: i18ns.i18nRescheduleReasonInputLabel,
          i18nRescheduleReasonInputPlaceholder: i18ns.i18nRescheduleReasonInputPlaceholder,
          qaRescheduleReasonInput: qaHooks.qaRescheduleReasonInput
        };

        const actions: SelectedJobActionsVm = {
          scheduleInProgress: dm.scheduleInProgress,
          overlapCheckInProgress: dm.overlapCheckInProgress,
          showOverlapsLoader: dm.overlapCheckInProgress,
          showScheduleLoader: dm.scheduleInProgress || dm.overlapCheckInProgressBeforeSchedule,
          showCheckOverlapsButton: dm.overlapsDetected,
          isScheduleButtonDisabled:
            dm.scheduleInProgress ||
            !dm.form.valid ||
            isTimeInvalid ||
            dm.readonly ||
            dm.overlapCheckInProgress ||
            dm.tradesPeople.length === 0,
          hideCloseIcon: dm.context === 'project' && dm.tradesPeople.length > 0,
          overlapsDetected: dm.overlapsDetected,
          i18nScheduleNowBtn: dm.overlapsDetected ? dm.i18nScheduleWithOverlapsBtn : dm.i18nScheduleNowBtn,
          scheduleActionColor: dm.overlapsDetected ? 'warning' : 'primary',
          i18nGoToProjectBtn: dm.i18nGoToProjectBtn,
          i18nCheckOverlapsBtn: dm.i18nCheckOverlapsBtn
        };

        const info: SelectedJobInfoVm = {
          jobType: dm.type,
          addressDisplay,
          phoneNumber: dm.phoneNumber,
          email: dm.email,
          customerName: dm.firstName && dm.lastName ? `${dm.firstName} ${dm.lastName}` : null
        };

        const selectedTradespeople: JobAssignment[] = dm.jobAssignments.map((x, idx) => ({
          ...x,
          assignmentType: idx === 0 ? 'LEAD' : 'SUPPORT',
          type: x.assignmentType === 'LEAD' ? 'filled' : 'outlined',
          color: x.assignmentType === 'LEAD' ? 'primary' : 'high',
          isSupportEngineer: x.assignmentType === 'SUPPORT',
          titleText: x.assignmentType === 'SUPPORT' ? dm.i18nSetLeadEngineerBtn : ''
        }));

        const assignedTradesPeople: AssignedTradesPeopleVm = {
          tradesPeople,
          form: dm.form,
          readonly: dm.readonly,
          actionsDisabled: dm.overlapCheckInProgress || dm.readonly,
          addTradespersonForm: dm.addTradespersonForm,
          tradesPeopleDisplay: ` ${dm.i18nTradesPeopleHeader} (${dm.tradesPeople.length})`,
          i18nAddSpecificTimeBtn: dm.i18nAddSpecificTimeBtn,
          i18nAddTradesPersonBtn: dm.i18nAddTradesPersonBtn,
          allTradespeople: dm.tradespeopleList,
          isAddingTradesperson: dm.isAddingTradesperson,
          showTradespersonRequiredError: !selectedTradespeople.length && !dm.isAddingTradesperson,
          qaTradespersonSelect: 'mdEngineerSelect',
          qaAddTradespersonBtn: 'mdAddEngineerBtn',
          qaConfirmAddTradespersonBtn: 'mdConfirmAddEngineerBtn',
          qaCancelAddTradespersonBtn: 'mdCancelAddEngineerBtn',
          i18nTradespersonRequiredError: dm.i18nEngineerRequiredError,
          i18nSelectTradespersonPlaceholder: dm.i18nSelectEngineerPlaceholder,
          i18nConfirmBtn: dm.i18nConfirmBtn,
          i18nCancelBtn: dm.i18nCancelBtn
        };

        vm = {
          customerName: `${dm.firstName} ${dm.lastName}`,
          projectId: dm.projectId,
          jobId: dm.id,
          form: dm.form,
          addTradespersonForm: dm.addTradespersonForm,
          // sub vms
          assignedTradesPeople,
          tradesPeople,
          mainJobTime,
          rescheduleReason,
          actions,
          info,
          // end sub vms
          overlapsDetected: dm.overlapsDetected,
          isAddingTradesperson: dm.isAddingTradesperson,
          i18nTradesPeopleSlotHeader: dm.i18nTradesPeopleSlotHeader,
          i18nOverlapsDetected: dm.i18nOverlapsDetected,
          scheduleValid: dm.form.valid,
          isInitialSchedule: dm.isInitialSchedule,
          scrollContentClass: dm.isInitialSchedule
            ? 'schedule-event-selected-job-details__content schedule-event-selected-job-details__content--scrollable'
            : dm.overlapsDetected
            ? 'schedule-event-selected-job-details__content schedule-event-selected-job-details__content--scrollable-reschedule-overlaps'
            : 'schedule-event-selected-job-details__content schedule-event-selected-job-details__content--scrollable-reschedule',
          showEngineerRequiredError: !tradesPeople.length && !dm.isAddingTradesperson,
          hidden: dm.hidden,
          readonly: dm.readonly
        };
      }
      // console.log('vm', vm);
      this.viewModel = vm;
      viewModelSubject$.next(this.viewModel);
      if (dm && dm.scheduleSuccess) {
        closedOnSuccessEventEmitter.emit(dm);
      }
    });
  }

  loadDetailsToggle(viewModelSubject: BehaviorSubject<SelectedJobDetailsToggleVm>) {
    this.repository.loadDetailsToggle((dm: SelectedJobDetailsToggleDm) => {
      viewModelSubject.next({ display: dm.display, hint: dm.hint, qaHook: 'mdTogglePane' });
    });
  }

  hideDisplay(): void {
    this.repository.showDetailsToggle(true);
  }

  showDisplay(): void {
    this.repository.showDetailsToggle(false);
  }

  localiseTimeSlots(): DropDownElement[] {
    return TIME_SLOTS.map(slot => {
      const date = JumptechDate.now().set({
        hour: Number(slot.label.split(':')[0]),
        minute: Number(slot.label.split(':')[1])
      });
      return {
        ...slot,
        label: date.toTimeFormat(false)
      };
    });
  }

  createCollisionInfo(collisions: { date: string; assignedToDisplayName: string }[], engineerName: string) {
    const engineerCollisions = collisions.filter(c => c.assignedToDisplayName === engineerName);
    if (engineerCollisions.length) {
      const collisionDate = engineerCollisions.map(ec => ec.date)[0];
      return {
        numberOfCollisions: engineerCollisions.length,
        collisionDisplayText: `Collisions Detected: ${JumptechDate.from(collisionDate).toDateTimeFormat()}`,
        collisionDateRaw: collisionDate
      };
    }

    return {
      numberOfCollisions: 0,
      collisionDisplayText: '',
      collisionDateRaw: ''
    };
  }

  calculateDurationDisplay(startIso: string, endIso: string, dm: SelectedJobDetailsDm): string {
    if (!startIso || !endIso) return '';

    const duration = JumptechDate.from(startIso).until(JumptechDate.from(endIso), {
      units: ['days', 'hours', 'minutes']
    });
    let displayDuration = `${dm.i18nDurationLabel}:`;
    if (duration.days === 1) {
      displayDuration = `${duration.days} ${dm.i18nDayLabel}`;
    } else if (duration.days > 1) {
      displayDuration = `${duration.days} ${dm.i18nDaysLabel}`;
    }
    if (duration.hours === 1) {
      displayDuration = `${displayDuration} ${duration.hours} ${dm.i18nHourLabel}`;
    } else if (duration.hours > 1) {
      displayDuration = `${displayDuration} ${duration.hours} ${dm.i18nHoursLabel}`;
    }
    if (duration.minutes > 1) {
      displayDuration = `${displayDuration} ${duration.minutes} ${dm.i18nMinutesLabel}`;
    }
    return displayDuration;
  }

  formatAddress(address: Partial<Job['address']>): string {
    if (!address) {
      return '';
    }
    const addressArray = [
      address.line1,
      address.line2,
      address.line3,
      address.line4,
      address.town,
      address.county,
      address.postCode
    ];
    return addressArray.filter(x => !!x).join(', ');
  }

  public closeMoreDetails(): void {
    this.repository.closeJobDetails();
    if (this.context !== 'project') {
      this.sjdPresenter.initDraggableItems();
    }
  }

  public addTradesperson(formEl: ElementRef): void {
    this.repository.addTradesperson();
    setTimeout((): void => {
      formEl.nativeElement.querySelector('[data-qa="mdEngineerSelect"] input').focus();
    });
  }

  public confirmAddTradesperson(): void {
    this.repository.confirmAddTradesperson();
  }

  public cancelAddTradesperson(): void {
    this.repository.cancelAddTradesperson();
  }

  public addSpecificSlot(vm: TradesPersonVm): void {
    this.repository.addSpecificSlotV2(vm);
  }

  public setLeadTradesperson(tradesperson: TradesPersonVm): void {
    this.repository.setLeadTradesperson(tradesperson);
  }

  public deleteTradesperson(tradesperson: TradesPersonVm): void {
    this.repository.deleteTradesperson(tradesperson);
  }

  public deleteTradespersonSlot(event: RemoveTradespersonSlotEvent): void {
    this.repository.deleteTradespersonSlot(event);
  }

  handleDateTimeChange(type: DateChangeType): void {
    this.repository.handleDateTimeChange(type);
  }

  handleSlotDateTimeChange(slotDateChangeEvent: DateTimeChangeEvent) {
    this.repository.handleSlotDateTimeChange(slotDateChangeEvent);
  }

  scheduleJob(ignoreOverlaps: boolean): void {
    this.repository.scheduleJob(ignoreOverlaps).then();
  }

  goToProject(): void {
    this.repository.goToProject();
  }

  checkOverlaps(): void {
    this.repository.checkOverlaps().then();
  }

  parseI18ns(dm: SelectedJobDetailsDm) {
    return {
      i18nProvisionallyScheduleBtn: dm.i18nProvisionallyScheduleBtn,
      i18nInvalidDateFormat: dm.i18nInvalidDateFormat,
      i18nStartDateLabel: dm.i18nStartDateLabel,
      i18nStartDatePlaceholder: dm.i18nStartDatePlaceholder,
      i18nStartDateRequired: dm.i18nStartDateRequired,
      i18nTradesPeopleHeader: dm.i18nTradesPeopleHeader,
      i18nTradesPeopleSubHeader: dm.i18nTradesPeopleSubHeader,
      i18nTradesPeopleSubHeaderTimeZone: dm.i18nTradesPeopleSubHeaderTimeZone,
      i18nTradesPeopleSlotHeader: dm.i18nTradesPeopleSlotHeader,
      i18nScheduleNowBtn: dm.i18nScheduleNowBtn,
      i18nScheduleWithOverlapsBtn: dm.i18nScheduleWithOverlapsBtn,
      i18nCheckOverlapsBtn: dm.i18nCheckOverlapsBtn,
      i18nOverlapsDetected: dm.i18nOverlapsDetected,
      i18nOverlapLabel: dm.i18nOverlapLabel,
      i18nGoToProjectBtn: dm.i18nGoToProjectBtn,
      i18nCancelBtn: dm.i18nCancelBtn,
      i18nConfirmBtn: dm.i18nConfirmBtn,
      i18nCloseBtn: dm.i18nCloseBtn,
      i18nAllDay: dm.i18nAllDay,
      i18nEndDateLabel: dm.i18nEndDateLabel,
      i18nEndDatePlaceholder: dm.i18nEndDatePlaceholder,
      i18nEndDateRequired: dm.i18nEndDateRequired,
      i18nEndDateBeforeStart: dm.i18nEndDateBeforeStart,
      i18nStartTimeLabel: dm.i18nStartTimeLabel,
      i18nEndTimeLabel: dm.i18nEndTimeLabel,
      i18nAddTradesPersonBtn: dm.i18nAddTradesPersonBtn,
      i18nSelectEngineerPlaceholder: dm.i18nSelectEngineerPlaceholder,
      i18nStartDateAfterEnd: dm.i18nStartDateAfterEnd,
      i18nSetLeadEngineerBtn: dm.i18nSetLeadEngineerBtn,
      i18nRemoveTradespersonBtn: dm.i18nRemoveTradespersonBtn,
      i18nRescheduleReasonInputLabel: dm.i18nRescheduleReasonInputLabel,
      i18nRescheduleReasonInputPlaceholder: dm.i18nRescheduleReasonInputPlaceholder,
      i18nEngineerRequiredError: dm.i18nEngineerRequiredError,
      i18nTimeIsInvalid: dm.i18nTimeIsInvalid,
      i18nTimeNotWithinJob: dm.i18nTimeNotWithinJob,
      i18nAddSpecificTimeBtn: dm.i18nAddSpecificTimeBtn
    };
  }

  parseQaHooks() {
    return {
      qaDragHandle: 'mdDragHandle',
      qaCloseBtn: 'mdCloseBtn',
      qaGoToProjectBtn: 'mdGoToProjectBtn',
      qaScheduleNowBtn: 'mdScheduleNowBtn',
      qaStartDateInput: 'mdStartDateInput',
      qaStartDateBtn: 'mdStartDateBtn',
      qaStartTimeSelect: 'mdStartTimeSelect',
      qaEndDateInput: 'mdEndDateInput',
      qaEndDateBtn: 'mdEndDateBtn',
      qaEndTimeSelect: 'mdEndTimeSelect',
      qaSetLeadEngineerBtn: 'mdSetLeadEngineerBtn',
      qaRemoveEngineerBtn: 'mdRemoveEngineerBtn',
      qaAddEngineerBtn: 'mdAddEngineerBtn',
      qaEngineerSelect: 'mdEngineerSelect',
      qaConfirmAddEngineerBtn: 'mdConfirmAddEngineerBtn',
      qaCancelAddEngineerBtn: 'mdCancelAddEngineerBtn',
      qaRescheduleReasonInput: 'mdRescheduleReasonInput',
      qaTradesPeopleSubHeaderTimeZone: 'mdTradesPeopleSubHeaderTimeZone'
    };
  }

  isSlotTimeInvalid(form, slotForm: FormGroup) {
    if (form.disabled || slotForm.disabled) {
      return false;
    }
    // check slot is within the bounds of the job
    const fullJobStartDate = form.get('fullJob').get('startDate').value;
    const fullJobStartIso = form.get('fullJob').get('startIso').value;
    const slotStartDate = slotForm.get('startDate').value;
    const slotStartIso = slotForm.get('startIso').value;

    const fullJobEndDate = form.get('fullJob').get('endDate').value;
    const fullJobEndIso = form.get('fullJob').get('endIso').value;
    const slotEndDate = slotForm.get('endDate').value;
    const slotEndIso = slotForm.get('endIso').value;

    const isSameStartDate =
      fullJobStartDate.day === slotStartDate.day &&
      fullJobStartDate.month === slotStartDate.month &&
      fullJobStartDate.year === slotStartDate.year;

    const isSameEndDate =
      fullJobEndDate.day === slotEndDate.day &&
      fullJobEndDate.month === slotEndDate.month &&
      fullJobEndDate.year === slotEndDate.year;

    if (isSameStartDate) {
      const jobStartTime = Date.parse(fullJobStartIso) / 1000;
      const slotStartTime = Date.parse(slotStartIso) / 1000;

      if (slotStartTime < jobStartTime) {
        form.setErrors({ time: 'invalidTime' });
        return true;
      }
    }

    if (isSameEndDate) {
      const jobEndTime = Date.parse(fullJobEndIso) / 1000;
      const slotEndTime = Date.parse(slotEndIso) / 1000;

      if (slotEndTime > jobEndTime) {
        form.setErrors({ time: 'invalidTime' });
        return true;
      }
    }

    return false;
  }

  isTimeInvalid = (form: FormGroup) => {
    if (form.disabled) {
      return false;
    }
    const startDate = form.get('startDate').value;
    const endDate = form.get('endDate').value;
    const startIso = form.get('startIso').value;
    const endIso = form.get('endIso').value;

    if (!startDate || !endDate || !startIso || !endIso) return false;

    const isSameDate =
      startDate.day === endDate.day && startDate.month === endDate.month && startDate.year === endDate.year;

    if (isSameDate) {
      const startTime = Date.parse(startIso) / 1000;
      const endTime = Date.parse(endIso) / 1000;

      if (startTime >= endTime) {
        form.setErrors({ time: 'invalidTime' });
        return true;
      }
    }

    return false;
  };

  generateOverlapText(overlap: TradesPersonOverlap, i18ns: { [key: string]: string }): string {
    const startDate = JumptechDate.from(overlap.start).toExportDateFormat();
    const startTime = JumptechDate.from(overlap.start).toTimeFormat();
    const endDate = JumptechDate.from(overlap.end).toExportDateFormat();
    const endTime = JumptechDate.from(overlap.end).toTimeFormat();

    if (overlap.type === 'Event') {
      const eventType = overlap.typeTranslation ?? overlap.title;
      const allDayEventTimeFrag =
        startDate === endDate ? `${startDate} ${i18ns.i18nAllDay}` : `${startDate} - ${endDate} ${i18ns.i18nAllDay}`;
      const timeFrag = overlap.allDayEvent
        ? `${allDayEventTimeFrag}`
        : `${startDate} ${startTime} - ${endDate} ${endTime}`;
      return `${eventType} ${timeFrag}`;
    }

    return `${overlap.title} ${startDate} ${startTime} - ${endDate} ${endTime}`;
  }
}
