import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JumptechDate, JumptechDateSettings, JumptechDuration } from '@jump-tech-frontend/domain';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { v4 } from 'uuid';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { UserService } from '../../auth/services/user.service';
import { Job, JobAssignment, JobAssignmentType } from '../../core/domain/job';
import { HttpGateway } from '../../core/http-gateway.service';
import { AggregatorReadOnlyService } from '../../project-detail/aggregator-read-only.service';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { ToasterService } from '../../toast/toast-service';
import {
  DateChangeType,
  DateTimeChangeEvent,
  ErrorType,
  JobAssignmentOverlapCollision,
  JobAssignmentOverlapInfo,
  RemoveTradespersonSlotEvent,
  ScheduleError,
  ScheduleI18n,
  ScheduleJobsDisplayDm,
  SchedulePayloadPostDto,
  ScheduleValidationInfo,
  SelectedJobAction,
  SelectedJobDetailsDm,
  SelectedJobDetailsToggleDm,
  TradesPerson,
  TradesPersonOverlap,
  TradesPersonSlot,
  TradesPersonVm
} from './schedule.model';
import { JOB_STATUSES_V2, TEMP_EVENT_ID, TIME_SLOTS } from './utils/schedule-constants';
import { ActiveJobActionPayload, ActiveJobState, JobInfo } from './utils/schedule-types';
import { isJobEditable, pad, trimEventIdSuffixes } from './utils/schedule.helper';

@Injectable({ providedIn: 'root' })
export class ScheduleRepositoryV3 {
  readyToScheduleSubscription: Subscription;
  errorsSubscription: Subscription;
  selectedJobDetailsSubscription: Subscription;
  activeSelectedStateSubscriptions: Subscription[] = [];
  detailsToggleSubscription: Subscription;

  errors$: Subject<ScheduleError>;
  rsj$: Subject<ScheduleJobsDisplayDm>;
  selectedJobDetails$: Subject<SelectedJobDetailsDm>;
  dt$: Subject<SelectedJobDetailsToggleDm>;
  activeSelectedState$: Subject<ActiveJobState>;

  dm: ScheduleJobsDisplayDm;
  cachedDm: ScheduleJobsDisplayDm;
  allTradespeopleList: any;
  filteredTradespeopleList: any;
  isReadonlyAggregator = false;
  isReadonlyForm = false;

  selectedJobDetailsDm: SelectedJobDetailsDm = {} as SelectedJobDetailsDm;

  selectedJobDetailsForm: FormGroup;
  addTradespersonForm: FormGroup;
  filtersForm: FormGroup;

  i18n: ScheduleI18n = {};
  timeSlots = TIME_SLOTS;

  initialState: ActiveJobState = {
    jobInformation: {
      jobAssignments: []
    } as JobInfo,
    activeEvents: [],
    action: null
  };
  currentState: ActiveJobState;
  previousState: ActiveJobState;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private readOnlyProjectService: AggregatorReadOnlyService,
    private userService: UserService
  ) {
    this.initV2().then();
  }

  async initV2(): Promise<void> {
    this.rsj$ = new Subject<ScheduleJobsDisplayDm>();
    this.errors$ = new Subject<ScheduleError>();
    this.activeSelectedState$ = new Subject<ActiveJobState>();
    this.selectedJobDetails$ = new Subject<SelectedJobDetailsDm>();
    this.dt$ = new Subject<SelectedJobDetailsToggleDm>();
    this.currentState = { jobInformation: null, activeEvents: [], action: null };
    this.initI18ns();
    this.filtersForm = this.fb.group({
      selectedJobTypes: [[]],
      freeText: ['']
    });
    this.isReadonlyAggregator = this.readOnlyProjectService.isReadOnly();
    await this.initDataLists();
  }

  listenToCalendarActiveState(cb): void {
    // base schedule component subscription
    this.activeSelectedStateSubscriptions.push(this.activeSelectedState$.subscribe(cb));
  }

  loadSelectedJobDetails(cb): void {
    // selected job details component subscription
    this.selectedJobDetailsSubscription?.unsubscribe();
    this.selectedJobDetailsSubscription = this.selectedJobDetails$.subscribe(cb);
    this.activeSelectedStateSubscriptions.push(
      this.activeSelectedState$.subscribe((state: ActiveJobState): void => {
        this.handleActiveStateChange(state);
      })
    );
  }

  private handleActiveStateChange(state: ActiveJobState): void {
    if (!state) {
      return;
    }
    console.log('\x1b[32m%s\x1b[0m', 'handleActiveStateChanges REPO', state);
    switch (state.action) {
      case SelectedJobAction.load: {
        this.isReadonlyForm = this.isReadonlyAggregator || !isJobEditable(state.jobInformation.status);
        this.setInitialState(state);
        this.initFormsV2();
        this.notifySelectedJobDetails();
        break;
      }
      case SelectedJobAction.destroy: {
        this.teardown();
        break;
      }
      case SelectedJobAction.fullJobDragged:
      case SelectedJobAction.fullJobResized:
      case SelectedJobAction.slotResizeDrag: {
        // update the form fullStart and end
        // notify the selected form
        this.updateFullJobForm(state.jobInformation);
        this.waitForMovedEventsSettledAndUpdateValid();
        break;
      }
      case SelectedJobAction.newResource: {
        this.updateFullJobForm(state.jobInformation);
        break;
      }
      case SelectedJobAction.resizeDragStart: {
        this.selectedJobDetailsForm.disable();
        break;
      }
      case SelectedJobAction.resizeDragStop: {
        this.selectedJobDetailsForm.enable();
        break;
      }
    }
  }

  private setInitialState(state: ActiveJobState): void {
    if (state === null) {
      this.currentState = { ...this.initialState };
      this.previousState = { ...this.initialState };
    } else {
      this.currentState = { ...state };
    }
  }

  // dispatch action handler calls state update
  stateReducer(
    currentState: ActiveJobState,
    action: SelectedJobAction,
    payload: ActiveJobActionPayload
  ): ActiveJobState {
    switch (action) {
      case SelectedJobAction.load: {
        return {
          ...currentState,
          action,
          jobInformation: payload.jobInformation,
          activeEvents: payload.activeEvents,
          payload
        };
      }
      case SelectedJobAction.destroy: {
        return {
          ...currentState,
          action
        };
      }
      case SelectedJobAction.removeTradespersonChange: {
        return {
          ...currentState,
          action,
          jobInformation: {
            ...currentState.jobInformation,
            jobAssignments: currentState.jobInformation.jobAssignments
              .filter(x => x.assignedTo !== payload.tradesperson.assignedTo)
              .map((ja, idx) => {
                if (idx === 0 && payload.tradesperson.assignmentType === 'LEAD') {
                  ja.assignmentType = 'LEAD';
                }
                return ja;
              })
          },
          payload
        };
      }
      case SelectedJobAction.addTradespersonChange: {
        return {
          ...currentState,
          action,
          jobInformation: {
            ...currentState.jobInformation,
            jobAssignments: [...this.currentState.jobInformation.jobAssignments, payload.jobAssignment]
          },
          payload
        };
      }
      case SelectedJobAction.removeTradespersonSlotChange: {
        const { tradesperson, slot, slotIndex } = payload;
        tradesperson.slots.splice(slotIndex, 1);
        if (!tradesperson.slots.length) {
          const assignment = currentState.jobInformation.jobAssignments.find(ja => ja.id == slot.assignmentId);
          if (assignment) {
            assignment.id = trimEventIdSuffixes(assignment.id);
            delete assignment.start;
            delete assignment.startDate;
            delete assignment.end;
            delete assignment.endDate;
            payload.jobAssignment = assignment;
          }
        } else {
          currentState.jobInformation.jobAssignments = currentState.jobInformation.jobAssignments.filter(
            ja => ja.id !== slot.assignmentId
          );
        }
        return {
          ...currentState,
          action,
          jobInformation: {
            ...currentState.jobInformation
          },
          payload
        };
      }
      case SelectedJobAction.addSlotChange: {
        let assignmentToUpdate = null;
        assignmentToUpdate = currentState.jobInformation.jobAssignments.find(ja => {
          if (payload.tradesperson.assignmentId.includes(TEMP_EVENT_ID)) {
            return (
              ja.assignedTo === payload.tradesperson.assignedTo &&
              payload.tradesperson.assignmentId.indexOf(ja.id) !== -1
            );
          } else {
            return ja.id === trimEventIdSuffixes(payload.tradesperson.assignmentId);
          }
        });

        if (assignmentToUpdate) {
          assignmentToUpdate.id = payload.tradesperson.assignmentId;
          assignmentToUpdate.assignedTo = payload.tradesperson.assignedTo;
          assignmentToUpdate.assignedToDisplayName = payload.tradesperson.assignedToDisplayName;
          assignmentToUpdate.assignmentType = payload.tradesperson.assignmentType;
          assignmentToUpdate.endDate = currentState.jobInformation.fullJobEnd;
          assignmentToUpdate.startDate = currentState.jobInformation.fullJobStart;
        } else {
          const newJobAssignment: JobAssignment = {
            id: payload.tradesperson.assignmentId,
            endDate: currentState.jobInformation.fullJobEnd,
            startDate: currentState.jobInformation.fullJobStart,
            assignedTo: payload.tradesperson.assignedTo,
            assignedToDisplayName: payload.tradesperson.assignedToDisplayName,
            assignmentType: payload.tradesperson.assignmentType
          };
          currentState.jobInformation.jobAssignments.push(newJobAssignment);
        }

        currentState.activeEvents = payload.activeEvents;

        return {
          ...currentState,
          action,
          jobInformation: {
            ...currentState.jobInformation,
            jobAssignments: currentState.jobInformation.jobAssignments
          },
          payload
        };
      }
      case SelectedJobAction.slotFormChange: {
        const toUpdate = currentState.jobInformation.jobAssignments.find(ja => ja.id === payload.slot.assignmentId);
        toUpdate.start = payload.slot.startDate;
        toUpdate.startDate = payload.slot.startDate;
        toUpdate.end = payload.slot.endDate;
        toUpdate.endDate = payload.slot.endDate;
        return {
          ...currentState,
          action,
          jobInformation: {
            ...currentState.jobInformation
          },
          lastChange: payload.lastChange,
          payload
        };
      }
      case SelectedJobAction.updateJobInfoAndEvents:
      case SelectedJobAction.newResource:
      case SelectedJobAction.slotResizeDrag:
      case SelectedJobAction.fullJobResized:
      case SelectedJobAction.fullJobDragged: {
        return {
          ...currentState,
          activeEvents: payload.activeEvents ? [...payload.activeEvents] : [...currentState.activeEvents],
          action,
          jobInformation: payload.jobInformation ? { ...payload.jobInformation } : { ...currentState.jobInformation }
        };
      }
      case SelectedJobAction.allTimesUpdated:
      case SelectedJobAction.resizeDragStart:
      case SelectedJobAction.resizeDragStop: {
        return {
          ...currentState,
          action
        };
      }
      case SelectedJobAction.postSchedule: {
        return null;
      }
    }
  }

  handleAction(action: SelectedJobAction, payload: ActiveJobActionPayload): void {
    this.previousState = { ...this.currentState };
    this.currentState = this.stateReducer(this.currentState, action, payload);
    this.activeSelectedState$.next(this.currentState);
  }

  private initFormsV2(): void {
    // read from first state
    // build the form
    // notify sjd presenter
    const tradesPeople = this.buildTradesPeopleFromAssignments(this.currentState.jobInformation.jobAssignments);
    this.currentState.jobInformation.tradesPeople = tradesPeople;

    // When dragging or assigning new jobs we want to default the time to 9am
    const assignmentStartTime = JumptechDate.from(new Date(new Date().setHours(9, 0, 0, 0)));
    const assignmentEndTime = assignmentStartTime.plus({
      hours: this.currentState.jobInformation?.defaultDuration ?? 2
    });
    const fullJobStart =
      this.currentState.jobInformation?.fullJobStart ?? this.currentState.jobInformation?.startDateTimestamp;
    const fullJobEnd =
      this.currentState.jobInformation?.fullJobEnd ?? this.currentState.jobInformation?.endDateTimestamp;
    const formStartTime = this.currentState.jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentStartTime.toIso())
      : this.isoStringToTime(fullJobStart);
    const formEndTime = this.currentState.jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentEndTime.toIso())
      : this.isoStringToTime(fullJobEnd);

    this.selectedJobDetailsForm = this.fb.group({
      id: [this.currentState.jobInformation?.actionId ?? null],
      fullJob: this.fb.group({
        startIso: [fullJobStart, [Validators.required]],
        startDate: [fullJobStart ? this.isoStringToDateStruct(fullJobStart) : null, [Validators.required]],
        endIso: [fullJobEnd, [Validators.required]],
        endDate: [fullJobEnd ? this.isoStringToDateStruct(fullJobEnd) : null, [Validators.required]],
        startTime: [formStartTime ?? null, [Validators.required]],
        endTime: [formEndTime ?? null, [Validators.required]],
        lastChange: [null]
      }),
      rescheduleReason: [null]
    });

    this.buildTradesPeopleForms(this.currentState.jobInformation);

    this.addTradespersonForm = this.fb.group({
      tradesperson: [null, [Validators.required]]
    });
  }

  waitForMovedEventsSettledAndUpdateValid(): void {
    setTimeout(() => {
      this.selectedJobDetailsDm.form.updateValueAndValidity();
      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    });
  }

  private updateFullJobForm(jobInfo: JobInfo): void {
    // first see if we need to build the form

    if (!this.currentState) {
      this.initFormsV2();
    }
    const formStartTime = this.isoStringToTime(jobInfo.fullJobStart);
    const formEndTime = this.isoStringToTime(jobInfo.fullJobEnd);
    this.selectedJobDetailsDm.form.get('fullJob').patchValue({
      startIso: jobInfo.fullJobStart,
      endIso: jobInfo.fullJobEnd,
      startDate: this.isoStringToDateStruct(jobInfo.fullJobStart),
      endDate: this.isoStringToDateStruct(jobInfo.fullJobEnd),
      startTime: formStartTime,
      endTime: formEndTime
    });
    this.patchTradesPeopleForms(jobInfo);
  }

  patchTradesPeopleForms(jobInfo: JobInfo): void {
    if (!this.previousState.jobInformation.tradesPeople) {
      this.previousState.jobInformation.tradesPeople = this.buildTradesPeopleFromAssignments(
        this.previousState.jobInformation.jobAssignments
      );
    }
    const listOfPreviousResources = this.previousState.jobInformation.tradesPeople.map(x => x.assignedTo);
    jobInfo.tradesPeople = this.buildTradesPeopleFromAssignments(jobInfo.jobAssignments);
    this.selectedJobDetailsDm.tradesPeople = jobInfo.tradesPeople;
    const newResources = jobInfo.tradesPeople.map(x => x.assignedTo);
    const resourcesToRemove = listOfPreviousResources.filter(prev => !newResources.includes(prev));

    jobInfo?.tradesPeople.forEach(tp => {
      let thisTpForm = this.selectedJobDetailsForm.get(tp.assignedTo);

      if (!thisTpForm) {
        // create
        this.selectedJobDetailsForm.addControl(
          tp.assignedTo,
          this.fb.group({
            assignedTo: [tp.assignedTo, Validators.required],
            assignedToDisplayName: [tp.assignedToDisplayName],
            assignmentId: [tp.assignmentId],
            assignmentType: [tp.assignmentType, Validators.required]
          })
        );
        thisTpForm = this.selectedJobDetailsForm.get(tp.assignedTo);
      }

      thisTpForm.patchValue({
        assignedTo: tp.assignedTo,
        assignedToDisplayName: tp.assignedToDisplayName,
        assignmentId: tp.assignmentId,
        assignmentType: tp.assignmentType
      });

      if (tp.slots.length) {
        const formArray = thisTpForm.get('slots') as FormArray;
        tp.slots.forEach((slot, i) => {
          const slotStartTime = this.isoStringToTime(slot.startDate);
          const slotEndTime = this.isoStringToTime(slot.endDate);

          formArray.at(i).patchValue({
            assignedTo: tp.assignedTo,
            assignedToDisplayName: tp.assignedToDisplayName,
            assignmentType: tp.assignmentType,
            assignmentId: slot.assignmentId,
            startDate: this.isoStringToDateStruct(slot.startDate),
            endDate: this.isoStringToDateStruct(slot.endDate),
            startTime: slotStartTime,
            endTime: slotEndTime,
            startIso: slot.startDate,
            endIso: slot.endDate,
            lastChange: null
          });
        });
      }
    });
    if (resourcesToRemove.length) {
      this.selectedJobDetailsForm.removeControl(resourcesToRemove[0]);
    }
    this.notifySelectedJobDetails();
  }

  buildTradesPeopleForms(jobInfo: JobInfo): void {
    jobInfo?.tradesPeople.forEach(tp => {
      this.selectedJobDetailsForm.addControl(
        tp.assignedTo,
        this.fb.group({
          assignedTo: [tp.assignedTo, Validators.required],
          assignedToDisplayName: [tp.assignedToDisplayName],
          assignmentId: [tp.assignmentId],
          assignmentType: [tp.assignmentType, Validators.required]
        })
      );

      if (tp.slots.length) {
        // build slot array
        const formArraySlots = [];
        tp.slots.forEach(slot => {
          const slotStartTime = this.isoStringToTime(slot.startDate);
          const slotEndTime = this.isoStringToTime(slot.endDate);

          formArraySlots.push(
            this.fb.group({
              assignedTo: [tp.assignedTo, Validators.required],
              assignedToDisplayName: [tp.assignedToDisplayName],
              assignmentType: [tp.assignmentType, Validators.required],
              assignmentId: [slot.assignmentId],
              // todo constraints around dates validation - no overlapping slots for same resource + bounds of full job + start before end
              startDate: [this.isoStringToDateStruct(slot.startDate), Validators.required],
              endDate: [this.isoStringToDateStruct(slot.endDate), Validators.required],
              startTime: [slotStartTime, Validators.required],
              endTime: [slotEndTime, Validators.required],
              startIso: slot.startDate,
              endIso: slot.endDate,
              lastChange: [null]
            })
          );
        });
        (this.selectedJobDetailsForm.controls[tp.assignedTo] as FormGroup).addControl(
          'slots',
          this.fb.array(formArraySlots)
        );
      }
    });
  }

  load(cb): void {
    this.readyToScheduleSubscription?.unsubscribe();
    this.readyToScheduleSubscription = this.rsj$.subscribe(cb);
    this.loadReadyToScheduleJobs().then();
  }

  private notifySelectedJobDetails(): void {
    this.filterTradespeopleOptions(this.currentState.jobInformation.jobAssignments);
    const tradesPeople = this.buildTradesPeopleFromAssignments(this.currentState.jobInformation.jobAssignments);
    this.currentState.jobInformation.tradesPeople = tradesPeople;

    if (this.isReadonlyForm) {
      this.selectedJobDetailsForm.disable();
    }

    this.selectedJobDetailsDm = {
      context: this.currentState.jobInformation.context,
      readonly: this.isReadonlyForm,
      id: this.currentState.jobInformation.id,
      scheduleSuccess: false,
      type: this.currentState.jobInformation.type,
      projectId: this.currentState.jobInformation.projectId,
      firstName: this.currentState.jobInformation.customerFirstName,
      lastName: this.currentState.jobInformation.customerLastName,
      phoneNumber: this.currentState.jobInformation.contactInfo.telephoneNumber,
      email: this.currentState.jobInformation.contactInfo.email,
      address: this.currentState.jobInformation.address,
      startDate: this.currentState.jobInformation.startDateTimestamp,
      endDate: this.currentState.jobInformation.endDateTimestamp,
      tradespeopleList: this.filteredTradespeopleList,
      jobAssignments: this.currentState.jobInformation.jobAssignments,
      tradesPeople,
      form: this.selectedJobDetailsForm,
      addTradespersonForm: this.addTradespersonForm,
      isAddingTradesperson: false,
      scheduleInProgress: false,
      overlapCheckInProgress: false,
      overlapCheckInProgressBeforeSchedule: false,
      overlapsDetected: false,
      isInitialSchedule: this.currentState.jobInformation.isInitialSchedule,
      hidden: false,
      i18nLead: this.i18n.lead,
      i18nConfirmBtn: this.i18n.confirmBtn,
      i18nCancelBtn: this.i18n.cancelBtn,
      i18nCloseBtn: this.i18n.closeBtn,
      i18nAllDay: this.i18n.allDay,
      i18nStartDatePlaceholder: this.i18n.startDatePlaceholder,
      i18nTradesPeopleHeader: this.i18n.tradesPeopleHeader,
      i18nTradesPeopleSubHeader: this.i18n.tradesPeopleSubHeader,
      i18nTradesPeopleSubHeaderTimeZone: this.i18n.tradesPeopleSubHeaderTimeZone,
      i18nTradesPeopleSlotHeader: this.i18n.tradesPeopleSlotHeader,
      i18nStartDateLabel: this.i18n.startDateLabel,
      i18nScheduleNowBtn: this.i18n.scheduleNowBtn,
      i18nScheduleWithOverlapsBtn: this.i18n.scheduleWithOverlapsBtn,
      i18nOverlapsDetected: this.i18n.overlapsDetected,
      i18nCheckOverlapsBtn: this.i18n.checkOverlapsBtn,
      i18nOverlapLabel: this.i18n.overlapLabel,
      i18nGoToProjectBtn: this.i18n.goToProjectBtn,
      i18nStartDateRequired: this.i18n.startDateRequired,
      i18nInvalidDateFormat: this.i18n.invalidDateFormat,
      i18nStartDateAfterEnd: this.i18n.startDateAfterEnd,
      i18nStartTimeLabel: this.i18n.startTimeLabel,
      i18nEndDatePlaceholder: this.i18n.endDatePlaceholder,
      i18nEndDateLabel: this.i18n.endDateLabel,
      i18nEndDateRequired: this.i18n.endDateRequired,
      i18nEndDateBeforeStart: this.i18n.endDateBeforeStart,
      i18nEndTimeLabel: this.i18n.endTimeLabel,
      i18nDurationLabel: this.i18n.durationLabel,
      i18nDayLabel: this.i18n.dayLabel,
      i18nDaysLabel: this.i18n.daysLabel,
      i18nHourLabel: this.i18n.hourLabel,
      i18nHoursLabel: this.i18n.hoursLabel,
      i18nMinutesLabel: this.i18n.minutesLabel,
      i18nAddTradesPersonLabel: this.i18n.addTradesPersonLabel,
      i18nSelectEngineerPlaceholder: this.i18n.selectEngineerPlaceholder,
      i18nProvisionallyScheduleBtn: this.i18n.provisionallyScheduleBtn,
      i18nAddTradesPersonBtn: this.i18n.addTradesPersonBtn,
      i18nSetLeadEngineerBtn: this.i18n.setLeadEngineerBtn,
      i18nRemoveTradespersonBtn: this.i18n.removeTradespersonBtn,
      i18nRescheduleReasonInputLabel: this.i18n.rescheduleReasonInputLabel,
      i18nRescheduleReasonInputPlaceholder: this.i18n.rescheduleReasonInputPlaceholder,
      i18nEngineerRequiredError: this.i18n.engineerRequiredError,
      i18nTimeIsInvalid: this.i18n.timeIsInvalid,
      i18nTimeNotWithinJob: this.i18n.i18nTimeNotWithinJob,
      i18nAddSpecificTimeBtn: this.i18n.addSpecificTimeBtn
    };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  resetFormData(): void {
    this.selectedJobDetailsDm.tradesPeople.forEach(tp => {
      this.selectedJobDetailsDm.form.removeControl(tp.assignedTo);
    });
    this.selectedJobDetailsDm.tradesPeople = [];
    this.selectedJobDetailsDm.jobAssignments = [];
    this.setInitialState(null);
    this.initFormsV2();
    this.selectedJobDetailsDm.form = this.selectedJobDetailsForm;
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  closeJobDetails(success = false, payload = null): void {
    this.resetFormData();
    if (success) {
      this.selectedJobDetailsDm = { scheduleSuccess: true, ...payload } as SelectedJobDetailsDm;
      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    } else {
      this.selectedJobDetails$.next(null);
      this.activeSelectedState$.next(null);
    }
  }

  loadDetailsToggle(cb): void {
    this.detailsToggleSubscription?.unsubscribe();
    this.detailsToggleSubscription = this.dt$.subscribe(cb);
  }

  showDetailsToggle(show: boolean): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, hidden: show };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    this.dt$.next({ display: show, hint: this.i18n.toggleSelectedJobHint });
  }

  addTradesperson(): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, isAddingTradesperson: true };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  addSpecificSlotV2(vm: TradesPersonVm): void {
    const tradesperson = this.selectedJobDetailsDm.tradesPeople.find(tp => tp.assignedTo == vm.assignedTo);
    const assignmentId = tradesperson.assignmentId
      ? `${tradesperson.assignmentId}-partial-${tradesperson.slots.length + 1}`
      : `${TEMP_EVENT_ID}-partial-${tradesperson.slots.length + 1}`;

    const updatedActiveEvents = this.currentState.activeEvents.map(ae => {
      if (ae.resourceId !== vm.assignedTo) {
        return ae;
      }
      return { ...ae, isPartial: true };
    });

    const payload: ActiveJobActionPayload = {
      tradesperson: { ...tradesperson, assignmentId },
      activeEvents: updatedActiveEvents
    };
    this.handleAction(SelectedJobAction.addSlotChange, payload);

    // update form
    const slotStartTime = this.isoStringToTime(this.currentState.jobInformation.fullJobStart);
    const slotEndTime = this.isoStringToTime(this.currentState.jobInformation.fullJobEnd);

    const newSlot = {
      assignmentId,
      endDate: this.currentState.jobInformation.fullJobStart,
      startDate: this.currentState.jobInformation.fullJobEnd
    };

    if (!tradesperson.slots.length) {
      (this.selectedJobDetailsForm.get(tradesperson.assignedTo) as FormGroup).addControl('slots', this.fb.array([]));
    }
    tradesperson.slots.push(newSlot);

    (this.selectedJobDetailsDm.form.get(vm.assignedTo).get('slots') as FormArray).push(
      this.fb.group({
        assignedTo: [vm.assignedTo, Validators.required],
        assignedToDisplayName: [vm.assignedToDisplayName],
        assignmentType: [vm.assignmentType, Validators.required],
        assignmentId: [assignmentId],
        startDate: [this.isoStringToDateStruct(this.currentState.jobInformation.fullJobStart), Validators.required],
        endDate: [this.isoStringToDateStruct(this.currentState.jobInformation.fullJobEnd), Validators.required],
        startTime: [slotStartTime, Validators.required],
        endTime: [slotEndTime, Validators.required],
        startIso: this.currentState.jobInformation.fullJobStart,
        endIso: this.currentState.jobInformation.fullJobEnd,
        lastChange: [null]
      })
    );

    this.notifySelectedJobDetails();
  }

  setLeadTradesperson(tradesperson: TradesPersonVm): void {
    if (tradesperson.assignmentType === 'LEAD') {
      return;
    }

    this.selectedJobDetailsDm.tradesPeople = this.selectedJobDetailsDm.tradesPeople.map(x => {
      const assType: JobAssignmentType = tradesperson.assignedTo === x.assignedTo ? 'LEAD' : 'SUPPORT';
      this.selectedJobDetailsForm.get(x.assignedTo).get('assignmentType').patchValue(assType);

      return {
        ...x,
        assignedTo: x.assignedTo,
        assignedToDisplayName: x.assignedToDisplayName,
        assignmentType: tradesperson.assignedTo === x.assignedTo ? 'LEAD' : 'SUPPORT'
      };
    });

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  confirmDeleteTradesperson(tradesperson: TradesPersonVm): void {
    const payload = { tradesperson };
    this.handleAction(SelectedJobAction.removeTradespersonChange, payload);

    this.selectedJobDetailsForm.patchValue({ jobAssignments: this.currentState.jobInformation.jobAssignments });
    this.selectedJobDetailsForm.removeControl(tradesperson.assignedTo);

    this.notifySelectedJobDetails();
  }

  deleteTradesperson(tradesperson: TradesPersonVm): void {
    // Check if tradesperson has slots
    if (tradesperson.slots.length) {
      let hasPreExistingSlots = false;
      for (const slot of tradesperson.slots) {
        if (!slot.assignmentId.includes('temp') && !slot.assignmentId.includes('partial')) {
          // Pre-existing slot - ask for delete confirmation
          this.confirmTradespersonDeleteDialog(tradesperson);
          hasPreExistingSlots = true;
          break;
        }
      }

      if (!hasPreExistingSlots) {
        this.confirmDeleteTradesperson(tradesperson);
      }
    } else {
      this.confirmDeleteTradesperson(tradesperson);
    }
  }

  confirmTradespersonDeleteDialog(tradesperson: TradesPersonVm) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.config = {
      title: this.i18n.confirmDelete,
      messages: [
        this.i18n.confirmDeleteTradespersonNameMessage.replace('%tp%', tradesperson.assignedToDisplayName),
        this.i18n.confirmDeleteTradespersonAllTimeslotsMessage
      ],
      confirm: this.i18n.confirmDelete,
      cancel: this.i18n.cancelBtn
    };
    modalRef.result
      .then(() => {
        this.confirmDeleteTradesperson(tradesperson);
      })
      .catch(() => {
        // Modal closed
      });
  }

  confirmTradespersonSlotDeleteDialog(tradesperson: TradesPerson, slot: TradesPersonSlot, slotIndex: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    const formattedStartDate = JumptechDate.from(slot.startDate).toExportDateTimeFormat();
    const formattedEndDate = JumptechDate.from(slot.endDate).toExportDateTimeFormat();
    modalRef.componentInstance.config = {
      title: this.i18n.confirmTimeslotDelete,
      messages: [
        this.i18n.confirmDeleteTradespersonNameMessage.replace('%tp%', tradesperson.assignedToDisplayName),
        this.i18n.confirmDeleteTradespersonSlotMessage
          .replace('%sd%', formattedStartDate)
          .replace('%ed%', formattedEndDate)
      ],
      confirm: this.i18n.confirmTimeslotDelete,
      cancel: this.i18n.cancelBtn
    };
    modalRef.result
      .then(() => {
        this.confirmDeleteTradespersonSlot(tradesperson, slotIndex, slot);
      })
      .catch(() => {
        // Modal closed
      });
  }

  deleteTradespersonSlot(event: RemoveTradespersonSlotEvent): void {
    // Check if it's a pre-existing slot
    const matchingTradesperson = this.selectedJobDetailsDm.tradesPeople.find(
      tp => tp.assignedTo === event.tradespersonId
    );
    const matchingSlot = matchingTradesperson.slots[event.index];
    if (matchingSlot.assignmentId.includes('temp') || matchingSlot.assignmentId.includes('partial')) {
      // Not pre-existing - proceed with slot delete
      this.confirmDeleteTradespersonSlot(matchingTradesperson, event.index, matchingSlot);
    } else {
      this.confirmTradespersonSlotDeleteDialog(matchingTradesperson, matchingSlot, event.index);
    }
  }

  confirmDeleteTradespersonSlot(tradesperson: TradesPerson, slotIndex: number, slot: TradesPersonSlot): void {
    const payload: ActiveJobActionPayload = { tradesperson, slot, slotIndex };
    this.handleAction(SelectedJobAction.removeTradespersonSlotChange, payload);

    // update the form
    (this.selectedJobDetailsForm.get(tradesperson.assignedTo).get('slots') as FormArray).removeAt(slotIndex);
    this.notifySelectedJobDetails();
  }

  filterTradespeopleOptions(jobAssignments: JobAssignment[]): void {
    const alreadyAssignedList = jobAssignments.map(a => a.assignedTo);
    this.filteredTradespeopleList = this.allTradespeopleList.filter(x => {
      return !alreadyAssignedList.includes(x.id);
    });
    this.selectedJobDetailsDm.tradespeopleList = this.filteredTradespeopleList;
  }

  confirmAddTradesperson(): void {
    const engineerData = this.selectedJobDetailsDm.addTradespersonForm.get('tradesperson').value;
    const newAssignment: JobAssignment = {
      assignedTo: engineerData.id,
      assignedToDisplayName: engineerData.name,
      id: TEMP_EVENT_ID, // todo maybe dont do this
      assignmentType: this.selectedJobDetailsDm.jobAssignments?.length ? 'SUPPORT' : 'LEAD'
    };

    const payload: ActiveJobActionPayload = {
      jobAssignment: newAssignment
    };

    this.handleAction(SelectedJobAction.addTradespersonChange, payload);

    this.addTradespersonToForm(payload.jobAssignment);
    this.resetAddTradespersonForm();
    this.notifySelectedJobDetails();
  }

  private addTradespersonToForm(person: JobAssignment): void {
    this.selectedJobDetailsForm.addControl(
      person.assignedTo,
      this.fb.group({
        assignedTo: [person.assignedTo, Validators.required],
        assignedToDisplayName: [person.assignedToDisplayName],
        assignmentId: [person.id], // todo temp id
        assignmentType: [person.assignmentType, Validators.required]
      })
    );
  }

  cancelAddTradesperson(): void {
    this.resetAddTradespersonForm();
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  resetAddTradespersonForm(): void {
    this.selectedJobDetailsDm.addTradespersonForm.patchValue({ tradesperson: null });
    this.selectedJobDetailsDm.isAddingTradesperson = false;
  }

  async loadReadyToScheduleJobs(showloader = true, scheduledJobId: string = null): Promise<void> {
    try {
      // notify loading state
      if (showloader) {
        this.dm = {
          loading: true,
          jobs: [],
          jobTypes: [],
          filtersForm: this.filtersForm,
          i18ns: this.i18n
        };
        this.rsj$.next(this.dm);
      }

      const dto: Job[] = [];
      let currentNextPageToken: string | undefined;

      const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch();
      const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

      dto.push(...unscheduledJobs);
      currentNextPageToken = nextPageToken;

      this.parseAndNotify(dto);

      while (currentNextPageToken) {
        const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch(currentNextPageToken);
        const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

        dto.push(...unscheduledJobs);
        currentNextPageToken = nextPageToken;

        this.parseAndNotify(dto);
      }
    } catch (e) {
      this.handleErrors(ErrorType.fetchReadyToScheduleJobs, e);
    }
  }

  private removeRecentlyScheduledJob(jobs: Job[], scheduledJobId: string = null) {
    if (!scheduledJobId) {
      return jobs;
    }
    // remove recently scheduled job from list, so we don't have to retry or make user wait too long
    return jobs.filter(({ id }) => id !== scheduledJobId);
  }

  private async getReadyToScheduleJobsBatch(
    nextPageToken?: string
  ): Promise<{ results: Job[]; nextPageToken?: string }> {
    const params = { readyToSchedule: true, isScheduled: false };

    if (nextPageToken !== undefined) {
      params['nextPageToken'] = nextPageToken;
    }

    return await this.gateway.get(`${environment.apiJobsUrl}`, params);
  }

  public getErrors(cb): void {
    this.errorsSubscription?.unsubscribe();
    this.errorsSubscription = this.errors$.subscribe(cb);
  }

  public clearErrors(): void {
    this.errors$.next({} as ScheduleError);
  }

  private setError(e: ErrorType): void {
    const message = this.i18nService.translate(e);
    this.errors$.next({
      message,
      qaErrorMessage: 'scheduleErrorMessage',
      qaClearErrorsButton: 'scheduleClearErrorsButton'
    });
  }

  private parseAndNotify(dto: Job[]): void {
    const jobTypes = this.buildJobTypesFilter(dto);

    const updatedDm: ScheduleJobsDisplayDm = {
      ...this.dm,
      loading: false,
      jobs: dto,
      filtersForm: this.filtersForm,
      jobTypes,
      i18ns: this.i18n
    };

    this.cachedDm = { ...updatedDm };
    this.filterReadyToSchedule();
  }

  public setSelectedJob(job: Job): void {
    this.dm = { ...this.dm, selectedJob: job };
    this.cachedDm = { ...this.dm };
    this.rsj$.next(this.dm);
  }

  private buildJobTypesFilter(dto: Job[]): DropDownElement[] {
    return dto
      .map(job => job.type)
      .filter((value, index, currentValue) => currentValue.indexOf(value) === index)
      .map(x => ({ id: x, name: x }));
  }

  filterReadyToSchedule(): void {
    const selectedJobTypes: string[] = this.filtersForm.get('selectedJobTypes').value;
    const freeText: string = this.filtersForm.get('freeText').value;
    let filteredJobs = this.cachedDm?.jobs;

    if (selectedJobTypes?.length) {
      filteredJobs = this.cachedDm.jobs.filter((job: Job) => selectedJobTypes?.includes(job.type));
    }
    if (freeText) {
      filteredJobs = filteredJobs.filter((job: Job) => {
        return (
          `${job.firstName} ${job.lastName}`.toLowerCase().includes(freeText.toLowerCase()) ||
          job.address?.postCode?.toLowerCase()?.replace(/\s/g, '').includes(freeText?.toLowerCase()?.replace(/\s/g, ''))
        );
      });
    }
    this.dm = {
      loading: false,
      jobs: filteredJobs,
      filtersForm: this.filtersForm,
      jobTypes: this.cachedDm ? this.cachedDm.jobTypes : this.dm.jobTypes,
      selectedJob: this.cachedDm ? this.cachedDm.selectedJob : this.dm.selectedJob,
      i18ns: this.i18n
    };
    this.rsj$.next(this.dm);
  }

  public handleErrors(type: ErrorType | unknown, e): void {
    if (!environment.production) {
      console.log(e);
    }
    switch (type) {
      case ErrorType.fetchReadyToScheduleJobs:
        this.setError(ErrorType.fetchReadyToScheduleJobs);
        this.rsj$.next({ ...this.dm, jobs: [], loading: false, i18ns: this.i18n });
        break;
      case ErrorType.fetchEngineers:
        this.setError(ErrorType.fetchEngineers);
        break;
      case ErrorType.selectRescheduleJob:
        this.setError(ErrorType.selectRescheduleJob);
        break;
      case ErrorType.unknown:
        this.setError(ErrorType.unknown);
        break;
      case ErrorType.scheduleJob:
        this.setError(ErrorType.scheduleJob);
        this.selectedJobDetailsForm.enable();
        this.selectedJobDetails$.next({
          ...this.selectedJobDetailsDm,
          scheduleInProgress: false,
          overlapCheckInProgress: false,
          overlapCheckInProgressBeforeSchedule: false
        });
        break;
      default:
        this.setError(ErrorType.unknown);
    }
  }

  parseEngineers(dto): DropDownElement[] {
    const engineers = dto.map(x => ({ name: x.key, id: x.value.split('|')[1] }));
    if (AuthenticationService.getTier() === 'support') {
      const currentUser = this.userService.currentUser;
      engineers.push({ name: `${currentUser.label} (Support)`, id: currentUser.id });
    }
    return engineers;
  }

  async fetchEngineers(): Promise<void> {
    try {
      const dto = await this.gateway.get(`${environment.apiCustomRoot}/core/users/engineer`, {});
      this.allTradespeopleList = this.parseEngineers(dto);
    } catch (e) {
      this.handleErrors(ErrorType.fetchEngineers, e);
    }
  }

  private buildTradesPeopleFromAssignments(jobAssignments: JobAssignment[]): TradesPerson[] {
    const tradesPeople: TradesPerson[] = [];

    jobAssignments.forEach((ja: JobAssignment): void => {
      const tradesPerson: TradesPerson = {
        assignedTo: null,
        assignedToDisplayName: null,
        assignmentType: null,
        slots: [],
        overlaps: []
      };
      // we don't have a tradesperson for this job assignment
      if (!tradesPeople.find(tp => tp.assignedTo === ja.assignedTo)) {
        tradesPerson.assignedTo = ja.assignedTo;
        tradesPerson.assignedToDisplayName = ja.assignedToDisplayName;
        tradesPerson.assignmentType = ja.assignmentType;
        // we don't have specific slot so add the assignment id
        if (!ja.startDate && !ja.endDate) {
          tradesPerson.assignmentId = ja.id;
        } else {
          tradesPerson.slots.push({
            assignmentId: ja.id,
            startDate: ja.startDate,
            endDate: ja.endDate
          });
        }
        tradesPeople.push(tradesPerson);
      } else {
        const existing = tradesPeople.find(tp => tp.assignedTo === ja.assignedTo);
        // add slot to existing
        existing.slots.push({
          assignmentId: ja.id,
          startDate: ja.startDate,
          endDate: ja.endDate
        });
      }
    });

    return tradesPeople;
  }

  private isReadonlyJob(jobInformation: JobInfo): boolean {
    // todo revisit this
    const editableStatuses = [
      ...JOB_STATUSES_V2['SCHEDULED'].legacyStatuses,
      ...JOB_STATUSES_V2['PROVISIONALLY_SCHEDULED'].legacyStatuses,
      ...JOB_STATUSES_V2['ABORTED'].legacyStatuses
    ];
    return !editableStatuses.includes(jobInformation.status);
  }

  private initI18ns(): void {
    this.i18n.projectType = this.i18nService.translate('common.projectType');
    this.i18n.jobType = this.i18nService.translate('common.jobType');
    this.i18n.lead = this.i18nService.translate('common.lead');
    this.i18n.nameLabel = this.i18nService.translate('common.name');
    this.i18n.allDay = this.i18nService.translate('common.allDay');
    this.i18n.contactInfoLabel = this.i18nService.translate('schedule.jobInformation.contactInfo');
    this.i18n.addressLabel = this.i18nService.translate('common.formFields.address');
    this.i18n.assignedTradespersonLabel = this.i18nService.translate('schedule.jobInformation.assignedTradesperson');
    this.i18n.freeTextFilterLabel = this.i18nService.translate('common.filter');
    this.i18n.jobTypesDropdownPlaceholder = this.i18nService.translate('common.showAll');
    this.i18n.titleJob = this.i18nService.translate('schedule.jobInformation.job');
    this.i18n.titlePostcode = this.i18nService.translate('common.postcode');
    this.i18n.buttonAssign = this.i18nService.translate('common.assign');
    this.i18n.buttonProject = this.i18nService.translate('common.project');
    this.i18n.buttonMoreDetails = this.i18nService.translate('schedule.jobInformation.buttons.moreDetails');
    this.i18n.titleJobsReadyToSchedule = this.i18nService.translate('schedule.jobDisplay.jobsReadyToSchedule');
    this.i18n.titleAllOtherJobs = this.i18nService.translate('schedule.jobDisplay.allOtherJobs');
    this.i18n.titleSelectedJobFromProject = this.i18nService.translate('schedule.jobDisplay.selectedJobFromProjects');
    this.i18n.startDatePlaceholder = this.i18nService.translate('schedule.formFields.startDate.placeholder');
    this.i18n.tradesPeopleHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleHeader');
    this.i18n.tradesPeopleSubHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleSubHeader');
    this.i18n.tradesPeopleSubHeaderTimeZone = this.i18nService.translate(
      'schedule.moreInfo.tradesPeopleSubHeaderTimeZone'
    );
    this.i18n.tradesPeopleSlotHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleSlotHeader');
    this.i18n.startDateLabel = this.i18nService.translate('schedule.formFields.startDate.label');
    this.i18n.scheduleNowBtn = this.i18nService.translate('schedule.moreInfo.scheduleNowBtn');
    this.i18n.scheduleWithOverlapsBtn = this.i18nService.translate('schedule.moreInfo.scheduleWithOverlapsBtn');
    this.i18n.checkOverlapsBtn = this.i18nService.translate('schedule.moreInfo.checkOverlapsBtn');
    this.i18n.overlapsDetected = this.i18nService.translate('schedule.moreInfo.overlapsDetected');
    this.i18n.overlapLabel = this.i18nService.translate('schedule.moreInfo.overlapLabel');
    this.i18n.goToProjectBtn = this.i18nService.translate('schedule.jobInformation.buttons.goToProject');
    this.i18n.startDateRequired = this.i18nService.translate('schedule.errors.startDateRequired');
    this.i18n.invalidDateFormat = this.i18nService.translate('schedule.errors.invalidDateFormat');
    this.i18n.startDateAfterEnd = this.i18nService.translate('schedule.errors.startDateAfterEnd');
    this.i18n.startTimeLabel = this.i18nService.translate('schedule.formFields.startTime.label');
    this.i18n.endDatePlaceholder = this.i18nService.translate('schedule.formFields.endDate.placeholder');
    this.i18n.endDateLabel = this.i18nService.translate('schedule.formFields.endDate.label');
    this.i18n.endDateRequired = this.i18nService.translate('schedule.errors.endDateRequired');
    this.i18n.endDateBeforeStart = this.i18nService.translate('schedule.errors.endDateBeforeStart');
    this.i18n.endTimeLabel = this.i18nService.translate('schedule.formFields.endTime.label');
    this.i18n.durationLabel = this.i18nService.translate('schedule.jobInformation.duration');
    this.i18n.dayLabel = this.i18nService.translate('schedule.moreInfo.dayLabel');
    this.i18n.daysLabel = this.i18nService.translate('schedule.moreInfo.daysLabel');
    this.i18n.hourLabel = this.i18nService.translate('schedule.moreInfo.hourLabel');
    this.i18n.hoursLabel = this.i18nService.translate('schedule.moreInfo.hoursLabel');
    this.i18n.minutesLabel = this.i18nService.translate('schedule.moreInfo.minutesLabel');
    this.i18n.addTradesPersonBtn = this.i18nService.translate('schedule.moreInfo.addTradesPersonBtn');
    this.i18n.selectEngineerPlaceholder = this.i18nService.translate('schedule.moreInfo.selectEngineerPlaceholder');
    this.i18n.confirmBtn = this.i18nService.translate('common.confirm');
    this.i18n.cancelBtn = this.i18nService.translate('common.cancel');
    this.i18n.closeBtn = this.i18nService.translate('common.close');
    this.i18n.provisionallyScheduleBtn = this.i18nService.translate('schedule.moreInfo.provisionallyScheduleBtn');
    this.i18n.scheduleJobLabel = this.i18nService.translate('schedule.moreInfo.scheduleJobLabel');
    this.i18n.jobHasBeenScheduledLabel = this.i18nService.translate('schedule.moreInfo.jobHasBeenScheduledLabel');
    this.i18n.setLeadEngineerBtn = this.i18nService.translate('schedule.moreInfo.setLeadEngineerBtn');
    this.i18n.removeTradespersonBtn = this.i18nService.translate('schedule.moreInfo.removeTradespersonBtn');
    this.i18n.freeTextFilterPlaceholder = this.i18nService.translate('schedule.jobDisplay.freeTextFilterPlaceholder');
    this.i18n.rescheduleReasonInputPlaceholder = this.i18nService.translate(
      'schedule.moreInfo.rescheduleReasonInputPlaceholder'
    );
    this.i18n.rescheduleReasonInputLabel = this.i18nService.translate('schedule.moreInfo.rescheduleReasonInputLabel');
    this.i18n.engineerRequiredError = this.i18nService.translate('schedule.moreInfo.engineerRequiredError');
    this.i18n.timeIsInvalid = this.i18nService.translate('schedule.moreInfo.timeIsInvalid');
    this.i18n.i18nTimeNotWithinJob = this.i18nService.translate('schedule.moreInfo.timeNotWithinJob');
    this.i18n.toggleSelectedJobHint = this.i18nService.translate('schedule.moreInfo.toggleHint');
    this.i18n.checkCollisionsBtn = this.i18nService.translate('schedule.moreInfo.checkCollisionsBtn');
    this.i18n.addSpecificTimeBtn = this.i18nService.translate('schedule.moreInfo.addSpecificTimeBtn');
    this.i18n.confirmDelete = this.i18nService.translate('schedule.selectedJobDetails.confirmDelete');
    this.i18n.confirmTimeslotDelete = this.i18nService.translate('schedule.selectedJobDetails.confirmTimeslotDelete');
    this.i18n.confirmDeleteTradespersonNameMessage = this.i18nService.translate(
      'schedule.selectedJobDetails.confirmDeleteTradespersonNameMessage'
    );
    this.i18n.confirmDeleteTradespersonAllTimeslotsMessage = this.i18nService.translate(
      'schedule.selectedJobDetails.confirmDeleteTradespersonAllTimeslotsMessage'
    );
    this.i18n.confirmDeleteTradespersonSlotMessage = this.i18nService.translate(
      'schedule.selectedJobDetails.confirmDeleteTradespersonSlotMessage'
    );
  }

  private async initDataLists(): Promise<void> {
    await this.fetchEngineers();
  }

  isoStringToDateStruct(isoString: string): NgbDateStruct {
    if (isoString) {
      const date = JumptechDate.from(isoString, { targetZone: JumptechDateSettings.defaultTimeZone }).toDateObject();
      return {
        year: date.year,
        month: date.month,
        day: date.day
      };
    }
  }

  isoStringToTime(isoString: string): string {
    if (isoString) {
      const date = JumptechDate.from(isoString, { targetZone: JumptechDateSettings.defaultTimeZone }).toDateObject();
      const hrs = pad(date.hour);
      const mins = pad(date.minute);
      return this.timeSlots.find(slot => slot.id === hrs + mins).name;
    }
  }

  patchFullJobIsos(): void {
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob') as FormGroup;

    const startDate = fullJobForm.get('startDate').value;
    const startTime = fullJobForm.get('startTime').value;
    const start = JumptechDate.from({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startTime.split(':')[0],
      minute: startTime.split(':')[1]
    }).toIso();
    fullJobForm.patchValue({ startIso: start });

    const endDate = fullJobForm.get('endDate').value;
    const endTime = fullJobForm.get('endTime').value;
    const end = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    }).toIso();

    fullJobForm.patchValue({ startIso: start, endIso: end });
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  handleDateTimeChange(type: DateChangeType): void {
    let allDatesBeingUpdated = false;
    if (this.selectedJobDetailsForm.get('fullJob').invalid) {
      return;
    }
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob') as FormGroup;
    if (type === 'start') {
      const startDate = fullJobForm.get('startDate').value;
      const startTime = fullJobForm.get('startTime').value;
      const startJtDate = JumptechDate.from({
        year: startDate.year,
        month: startDate.month,
        day: startDate.day,
        hour: startTime.split(':')[0],
        minute: startTime.split(':')[1]
      });
      const start = startJtDate.toIso();
      fullJobForm.patchValue({ startIso: start, lastChange: type });
      const startDiff = startJtDate.diff(JumptechDate.from(this.currentState.jobInformation.fullJobStart), {
        units: ['months', 'days', 'hours', 'minutes']
      });

      // update all the other dates if months or days are different
      if (startDiff.months || startDiff.days) {
        allDatesBeingUpdated = true;
        this.updateDatesFromStartDiff(start, startDiff);
      }
    } else {
      const endDate = fullJobForm.get('endDate').value;
      const endTime = fullJobForm.get('endTime').value;
      const end = JumptechDate.from({
        year: endDate.year,
        month: endDate.month,
        day: endDate.day,
        hour: endTime.split(':')[0],
        minute: endTime.split(':')[1]
      }).toIso();
      fullJobForm.patchValue({ endIso: end, lastChange: type });
    }

    // todo fix the below
    // if (!startDate || !endDate || !startTime || !endTime) {
    //   this.selectedJobDetailsForm.patchValue({ startIso: null, endIso: null });
    //   this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    //   return;
    // }

    if (!allDatesBeingUpdated) {
      // todo update current state in the reducer
      this.currentState.jobInformation.fullJobStart = fullJobForm.get('startIso').value;
      this.currentState.jobInformation.fullJobEnd = fullJobForm.get('endIso').value;
      this.currentState.lastChange = type;
      this.currentState.action = SelectedJobAction.fullJobFormChange;
      this.activeSelectedState$.next(this.currentState);

      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    }
  }

  /**
   * Given a duration difference, apply it across the full job end date
   * as well as the start and end dates of all partial events.
   * Notify the component after all the updates so that all constraints
   * are updated.
   */
  private updateDatesFromStartDiff(startIso: string, diff: JumptechDuration): void {
    // update end date
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob') as FormGroup;
    const endDate = fullJobForm.get('endDate').value;
    const endTime = fullJobForm.get('endTime').value;
    const newEndDate = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    })
      .plus(diff)
      .toIso();

    this.selectedJobDetailsDm.form.get('fullJob').patchValue({
      startIso,
      endIso: newEndDate,
      startDate: this.isoStringToDateStruct(startIso),
      endDate: this.isoStringToDateStruct(newEndDate),
      startTime: this.isoStringToTime(startIso),
      endTime: this.isoStringToTime(newEndDate)
    });

    this.currentState.jobInformation.fullJobStart = fullJobForm.get('startIso').value;
    this.currentState.jobInformation.fullJobEnd = fullJobForm.get('endIso').value;
    this.currentState.lastChange = 'start';
    this.currentState.action = SelectedJobAction.fullJobFormChange;
    this.activeSelectedState$.next(this.currentState);

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);

    // update slots
    const tradespeopleWithSlots = this.currentState.jobInformation.tradesPeople
      .filter(tp => tp.slots.length)
      .map(tpWithSlots => tpWithSlots.assignedTo);

    for (const tradespersonId of tradespeopleWithSlots) {
      const slots = this.selectedJobDetailsDm.form.get(tradespersonId).get('slots') as FormArray;
      for (let i = 0; i < slots.length; i++) {
        const slotForm = slots.at(i);
        const startDate = slotForm.get('startDate').value;
        const endDate = slotForm.get('endDate').value;
        const startTime = slotForm.get('startTime').value;
        const endTime = slotForm.get('endTime').value;

        const newStartIso = JumptechDate.from({
          year: startDate.year,
          month: startDate.month,
          day: startDate.day,
          hour: startTime.split(':')[0],
          minute: startTime.split(':')[1]
        })
          .plus(diff)
          .toIso();

        const newEndIso = JumptechDate.from({
          year: endDate.year,
          month: endDate.month,
          day: endDate.day,
          hour: endTime.split(':')[0],
          minute: endTime.split(':')[1]
        })
          .plus(diff)
          .toIso();

        slotForm.patchValue({
          startIso: newStartIso,
          endIso: newEndIso,
          startDate: this.isoStringToDateStruct(newStartIso),
          endDate: this.isoStringToDateStruct(newEndIso),
          startTime: this.isoStringToTime(newStartIso),
          endTime: this.isoStringToTime(newEndIso)
        });

        const assignmentId = slotForm.get('assignmentId').value;
        const tradesperson = this.currentState.jobInformation.tradesPeople.find(tp => tp.assignedTo === tradespersonId);

        const payload: ActiveJobActionPayload = {
          slot: { assignmentId, endDate: newEndIso, startDate: newStartIso },
          tradesperson,
          lastChange: 'start'
        };
        this.handleAction(SelectedJobAction.slotFormChange, payload);

        // update the dm
        const person = this.selectedJobDetailsDm.tradesPeople.find(tp => tp.assignedTo === tradespersonId);
        const thisSlot = person.slots[i];
        thisSlot.startDate = newStartIso;
        thisSlot.endDate = newEndIso;
        this.waitForMovedEventsSettledAndUpdateValid();
      }
    }

    this.handleAction(SelectedJobAction.allTimesUpdated, {});
  }

  handleSlotDateTimeChange(slotDateChangeEvent: DateTimeChangeEvent): void {
    // todo maybe we can have a single dateTime change handler
    const slotForm = (
      this.selectedJobDetailsDm.form.get(slotDateChangeEvent.tradespersonId).get('slots') as FormArray
    ).at(slotDateChangeEvent.index);

    if (slotForm.invalid) {
      return;
    }

    const startDate = slotForm.get('startDate').value;
    const endDate = slotForm.get('endDate').value;
    const startTime = slotForm.get('startTime').value;
    const endTime = slotForm.get('endTime').value;

    const startIso = JumptechDate.from({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startTime.split(':')[0],
      minute: startTime.split(':')[1]
    }).toIso();
    const endIso = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    }).toIso();

    slotForm.patchValue({ startIso, endIso, lastChange: slotDateChangeEvent.type });

    const assignmentId = slotForm.get('assignmentId').value;
    const tradesperson = this.currentState.jobInformation.tradesPeople.find(
      tp => tp.assignedTo === slotDateChangeEvent.tradespersonId
    );

    const payload: ActiveJobActionPayload = {
      slot: { assignmentId, endDate: endIso, startDate: startIso },
      tradesperson,
      lastChange: slotDateChangeEvent.type
    };
    this.handleAction(SelectedJobAction.slotFormChange, payload);

    // update the dm
    const person = this.selectedJobDetailsDm.tradesPeople.find(
      tp => tp.assignedTo === slotDateChangeEvent.tradespersonId
    );
    const thisSlot = person.slots[slotDateChangeEvent.index];
    thisSlot.startDate = startIso;
    thisSlot.endDate = endIso;
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  async checkOverlaps(): Promise<void> {
    await this.validateJobOverlaps();
  }

  private buildPayload(): SchedulePayloadPostDto {
    const assignments: JobAssignment[] = [];
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob');

    this.selectedJobDetailsDm.tradesPeople.forEach(tp => {
      if (tp.slots.length) {
        tp.slots.forEach(slot => {
          const generatedId = v4();
          const jobAssignment: JobAssignment = {
            start: slot.startDate,
            startDate: slot.startDate,
            end: slot.endDate,
            endDate: slot.endDate,
            assignedTo: tp.assignedTo,
            assignedToDisplayName: tp.assignedToDisplayName,
            assignmentType: tp.assignmentType
          };
          if (slot.assignmentId && !slot.assignmentId.includes(TEMP_EVENT_ID)) {
            jobAssignment.id = trimEventIdSuffixes(slot.assignmentId);
          } else {
            jobAssignment.id = generatedId;
          }
          assignments.push(jobAssignment);
        });
      } else {
        const jobAssignment: JobAssignment = {
          assignedTo: tp.assignedTo,
          assignedToDisplayName: tp.assignedToDisplayName,
          assignmentType: tp.assignmentType
        };
        if (tp.assignmentId && !tp.assignmentId.includes(TEMP_EVENT_ID)) {
          jobAssignment.id = trimEventIdSuffixes(tp.assignmentId);
        } else {
          const generatedId = v4();
          jobAssignment.id = generatedId;
        }
        assignments.push(jobAssignment);
      }
    });

    return {
      id: this.selectedJobDetailsForm.get('id').value,
      start: fullJobForm.get('startIso').value,
      end: fullJobForm.get('endIso').value,
      jobType: this.selectedJobDetailsDm.type,
      jobAssignments: assignments,
      rescheduleReason: this.selectedJobDetailsForm.get('rescheduleReason')?.value ?? ''
    };
  }

  async validateJobOverlaps(checkingBeforeSchedule = false): Promise<void> {
    const payload = this.buildPayload();
    this.selectedJobDetailsDm = {
      ...this.selectedJobDetailsDm,
      overlapCheckInProgress: true,
      overlapCheckInProgressBeforeSchedule: checkingBeforeSchedule
    };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    this.selectedJobDetailsForm.disable();

    try {
      const headers = {
        'x-jt-project-id': this.selectedJobDetailsDm.projectId,
        skip: 'true'
      };
      await this.gateway.post(
        `${environment.apiProjectUrl}/${this.selectedJobDetailsDm.projectId}/schedule/validate`,
        payload,
        headers
      );
      await this.scheduleJob(true);
    } catch (e) {
      if (e.status === 409) {
        this.parseJobOverlaps(e.error.conflicts as ScheduleValidationInfo);
      } else {
        this.handleErrors(ErrorType.scheduleJob, e);
      }
    }
  }

  private slotOverlapsWithCollision(slot: TradesPersonSlot, collision: JobAssignmentOverlapCollision): boolean {
    // add specific check for all day events that start and end on the same day
    if (collision.time.allDayEvent && collision.time.start === collision.time.end) {
      const allDayEventStartDate = JumptechDate.from(collision.time.start).toExportDateFormat();
      const slotStartDate = JumptechDate.from(slot.startDate).toExportDateFormat();
      const slotEndDate = JumptechDate.from(slot.endDate).toExportDateFormat();

      return slotStartDate === allDayEventStartDate || slotEndDate === allDayEventStartDate;
    }
    const collisionStartMillis = JumptechDate.from(collision.time.start).toMillis();
    const collisionEndMillis = JumptechDate.from(collision.time.end).toMillis();
    const slotStartMillis = JumptechDate.from(slot.startDate).toMillis();
    const slotEndMillis = JumptechDate.from(slot.endDate).toMillis();
    const collisionStartOrEndIsBetweenSlotStartAndEnd =
      (collisionStartMillis > slotStartMillis && collisionStartMillis < slotEndMillis) ||
      (collisionEndMillis > slotStartMillis && collisionEndMillis < slotEndMillis);
    const slotStartOrEndIsBetweenCollisionStartAndEnd =
      (slotStartMillis > collisionStartMillis && slotStartMillis < collisionEndMillis) ||
      (slotEndMillis > collisionStartMillis && slotEndMillis < collisionEndMillis);

    return collisionStartOrEndIsBetweenSlotStartAndEnd || slotStartOrEndIsBetweenCollisionStartAndEnd;
  }

  private findDistinctOverlaps(jobInfoOverlaps: ScheduleValidationInfo): JobAssignmentOverlapInfo[] {
    const distinctOverlaps: JobAssignmentOverlapInfo[] = [];
    const checkedJobIds: string[] = [];
    const jobAssignmentsToCheck: JobAssignmentOverlapInfo[] = JSON.parse(
      JSON.stringify(jobInfoOverlaps.jobAssignments)
    );
    for (const ja of jobAssignmentsToCheck) {
      ja.collisions = ja.collisions.filter(collision => !checkedJobIds.includes(collision.id));
      for (const collision of ja.collisions) {
        if (!checkedJobIds.includes(collision.id)) {
          checkedJobIds.push(collision.id);
        }
      }
      if (ja.collisions.length) {
        distinctOverlaps.push(ja);
      }
    }

    return distinctOverlaps;
  }

  private parseJobOverlaps(jobInfoOverlaps: ScheduleValidationInfo): void {
    const distinctOverlaps = this.findDistinctOverlaps(jobInfoOverlaps);
    // reset tp overlaps
    this.selectedJobDetailsDm.tradesPeople.forEach(tp => (tp.overlaps = []));
    for (const overlap of distinctOverlaps) {
      const tp = this.selectedJobDetailsDm.tradesPeople.find(tp => tp.assignedTo === overlap.assignedTo);
      const tpHasSlots = tp.slots.length;
      for (const collision of overlap.collisions) {
        const overlappingSlotIndexes = [];
        if (tpHasSlots) {
          tp.slots.forEach((slot, index) => {
            if (this.slotOverlapsWithCollision(slot, collision)) {
              overlappingSlotIndexes.push(index);
            }
          });
        }
        const tpOverlap: TradesPersonOverlap = {
          start: collision.time.start,
          end: collision.time.end,
          allDayEvent: collision.time.allDayEvent,
          type: collision.type,
          title: collision.title,
          overlappingSlotIndexes,
          typeTranslation: collision.typeTranslationKey
            ? this.i18nService.translate(collision.typeTranslationKey)
            : null
        };
        tp.overlaps.push(tpOverlap);
      }
    }

    this.selectedJobDetailsDm = {
      ...this.selectedJobDetailsDm,
      overlapsDetected: true,
      overlapCheckInProgress: false,
      overlapCheckInProgressBeforeSchedule: false
    };
    this.selectedJobDetailsForm.enable();
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  async scheduleJob(ignoreOverlaps = false): Promise<void> {
    if (!ignoreOverlaps) {
      await this.validateJobOverlaps(true);
      return;
    }
    const payload = this.buildPayload();

    this.selectedJobDetails$.next({ ...this.selectedJobDetailsDm, scheduleInProgress: true });
    try {
      const headers = {
        'x-jt-project-id': this.selectedJobDetailsDm.projectId
      };
      await this.gateway.post(
        `${environment.apiProjectUrl}/${this.selectedJobDetailsDm.projectId}/schedule`,
        payload,
        headers,
        { ignoreState: 'true' }
      );
      this.toasterService.pop('success', this.i18n.scheduleJobLabel, this.i18n.jobHasBeenScheduledLabel);
      const jobId = this.selectedJobDetailsDm.id.split('--')[0];
      await this.optimisticUpdateJobsList(jobId);
      this.closeJobDetails(true, { ...payload, projectId: this.selectedJobDetailsDm.projectId });
      this.modalService.dismissAll();
      this.handleAction(SelectedJobAction.postSchedule, {});
    } catch (e) {
      this.handleErrors(ErrorType.scheduleJob, e);
    }
  }

  async optimisticUpdateJobsList(jobId: string): Promise<void> {
    if (this.cachedDm.jobs && this.cachedDm.jobs.length) {
      const updated = { ...this.cachedDm, jobs: this.dm?.jobs?.filter(j => j.id !== jobId) };
      this.rsj$.next(updated);
      await this.loadReadyToScheduleJobs(false, jobId);
    }
  }

  goToProject(id?: string): void {
    const projectId: string = id ?? this.selectedJobDetailsDm.projectId;
    this.modalService.dismissAll();
    this.router.navigate([`/project/${projectId}`]).catch(console.log);
  }

  private teardown(): void {
    this.setInitialState(null);
    this.selectedJobDetails$.next(null);
    this.activeSelectedStateSubscriptions?.forEach(sub => sub.unsubscribe());
  }
}
