import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COMMONS_TRANSLATIONS_PROVIDER } from '@jump-tech-frontend/app-config';
import { AddressLookupV2Vm } from './address-lookup-v2.vm';
import { IAddressV2 } from './domain/address-v2';
import { transformLegacyAddress } from './search/address.utils';
import { AddressProvider } from './domain/address-provider';

@Injectable()
export class AddressLookupV2Presenter {
  public readonly translationsProvider = inject(COMMONS_TRANSLATIONS_PROVIDER);
  load(
    vm$: BehaviorSubject<AddressLookupV2Vm | null>,
    countryCodes: string[],
    label: string,
    provider: AddressProvider,
    address?: IAddressV2 | null
  ) {
    address = transformLegacyAddress(address);
    vm$.next({
      enterManually: this.translationsProvider.getTranslation('enterAddressManually'),
      countryCodes: countryCodes?.length ? countryCodes : ['gb'],
      label,
      provider,
      address
    });
  }
}
