import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { JsonPipe, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DateChangeType, SelectedJobTimeVm } from '../../../../schedule.model';
import { FeatureFlagDirective } from '../../../../../../core/feature-flag/featureFlagDirective';

@Component({
  selector: 'schedule-event-selected-job-details-date-time-form',
  templateUrl: 'schedule-event-selected-job-details-date-time-form.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    JsonPipe,
    FeatureFlagDirective
  ]
})
export class ScheduleEventSelectedJobDetailsDateTimeFormComponent {
  @Input() vm: SelectedJobTimeVm;
  @Output() handleDateTimeChange: EventEmitter<DateChangeType> = new EventEmitter<DateChangeType>();

  constructor() {}
}
