import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalConfig } from './confirm-modal.config';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-confirm-modal',
  template: `<div class="modal-wrapper modal--confirm">
    <div class="modal-header">
      <div>{{ config.title }}</div>
    </div>
    <div class="modal-body" *ngIf="config.messages">
      <div *ngFor="let message of config.messages">{{ message }}</div>
    </div>
    <div class="modal-body" *ngIf="config.requires">
      <div>{{ config.requires.failMessage }}</div>
    </div>
    <div class="modal-footer text-center">
      <jui-button
        [attr.data-qa]="'confirmModalBtn-cancel'"
        class="modal-footer__action-btn"
        color="low"
        size="sm"
        [disabled]="config.cancelDisabled"
        (click)="cancel($event)"
        >{{ config.cancel || ('common.no' | transloco) }}
      </jui-button>
      <jui-button
        [attr.data-qa]="'confirmModalBtn-confirm'"
        class="modal-footer__action-btn"
        size="sm"
        [loading]="config.confirmLoading"
        [disabled]="config.confirmDisabled"
        (click)="confirm($event)"
        >{{ config.confirm || ('common.yes' | transloco) }}
      </jui-button>
    </div>
  </div>`,
  standalone: true,
  imports: [NgIf, NgFor, CoreComponentsAngularModule, TranslocoModule]
})
export class ConfirmModalComponent {
  @Input() config: ConfirmModalConfig;
  @Input() parameters: any;

  constructor(public activeModal: NgbActiveModal) {}

  confirm($event) {
    $event.preventDefault();

    // Override default confirm logic with custom callback
    if (this.config.onConfirm) {
      this.config.onConfirm();
    } else {
      this.activeModal.close();
    }
  }

  cancel($event) {
    $event.preventDefault();
    this.activeModal.dismiss();
  }
}
