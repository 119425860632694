import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { BaseQuestionComponent } from '@jump-tech-frontend/cards';
import { QuestionBase } from '@jump-tech-frontend/domain';
import * as JmesPath from 'jmespath';
import { MultipleSelectionDropdownComponent } from './multiple-selection-dropdown.component';
import { NgIf } from '@angular/common';

export interface ExtendedQuestion {
  label: string;
  key: string;
  required?: boolean;
  options?: { key: string; value: string }[];
  singleSelection?: boolean;
}

interface Option {
  id: string;
  name: string;
}

@Component({
  selector: 'app-ng-select-dropdown',
  template: `
    <div [formGroup]="form" *ngIf="items.length && show">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key"
        >{{ question.label }}
        <span *ngIf="question.required"> *</span>
      </label>
      <app-multi-select
        [items]="items"
        [selectedItems]="toSelectedItems(question)"
        (itemsChanged)="onSelectionChanged($event)"
        [singleSelection]="singleSelection"
      >
      </app-multi-select>
    </div>
  `,
  standalone: true,
  imports: [NgIf, MultipleSelectionDropdownComponent]
})
export class NgSelectDropdownComponent extends BaseQuestionComponent implements OnInit {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() selectedItems?: string[];
  @Input() project?: unknown;

  private _singleSelection = true;
  private _items: Option[] = [];

  get items() {
    return this._items;
  }

  @Input() set singleSelection(singleSelection: boolean) {
    this._singleSelection = singleSelection;
  }

  get singleSelection() {
    return this._singleSelection;
  }

  ngOnInit(): void {
    if (!this._items.length) {
      if (this.question.projectDatasource?.transform && this.project) {
        this.mapData(this.project, this.question.projectDatasource.transform, this.question, this.form);
      }
      this._items = this.question.options.map(questionOption => ({
        id: questionOption.value,
        name: questionOption.key,
        description: questionOption.description
      }));
    }
  }

  override get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  onSelectionChanged($event: any[]) {
    const value = !$event.length ? null : this.singleSelection ? $event[0] : $event;
    this.form.get(this.question.key).patchValue(value);
    this.form.get(this.question.key).markAsDirty();
  }

  toSelectedItems(question: Partial<QuestionBase<string>>) {
    const formValue = this.form.get(question.key);
    if (!formValue.value) {
      return [];
    }
    return Array.isArray(formValue.value) ? formValue.value : [formValue.value];
  }

  public mapData(context: unknown, transform: string, question?: Partial<QuestionBase<string>>, form?: FormGroup) {
    const options: { key: string; value: string; description?: string }[] = [];
    const transformed = JmesPath.search(context, transform);
    if (!transformed || !Array.isArray(transformed)) {
      return;
    }
    transformed.forEach(item => {
      options.push({
        key: item.text,
        value: item.id,
        description: item.description
      });
    });
    if (question && form) {
      if (options.length) {
        question.options = options.sort((a, b) => {
          return a.key?.localeCompare(b.key) ?? 0;
        });
      } else if (!question.options || !question.options.length) {
        question.options = [];
      }
    }
  }
}
